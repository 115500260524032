import styled from 'styled-components';

export default styled.div`
    .admin-first-container {
        background-image: linear-gradient(31.53deg,#f4f8ff 3.69%,#dbe9ff 78.33%,#3f88ff 165.68%);
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: center;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .list-chats-container {
        padding: 20px;
    }

    .search-identifier-form {
        position: relative;
    }

    .chat-table, .search-identifier-form {
        max-width: 1200px;
        min-width: 500px;
        width: 100%;
        display: block;
        margin: auto;
    }

    .search-identifier-input, .search-identifier-button {
        font-size: 18px;
        position: absolute;
    }

    .search-identifier-button {
        right: 0px;
    }

    .chat-table {
        margin-top: 100px;
    }

    .chat-table thead, .chat-table thead tr {
        font-size: 18px;
        width: 100%;
        display: block;
    }

    .chat-table th, .chat-item td {
        width: 33%;
        display: inline-block;
        text-align: center;
    }

    .chat-item {
        border-bottom: 1px solid #007bff7a;
        margin-bottom: 10px;
        padding-bottom: 5px;
    }

`;