import axios from 'axios';
import config from  '../config/config.json';
const clientId = window.localStorage.getItem('client-id');
const token = window.localStorage.getItem('access-token');

export const listChats = (client, identifier) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    const queryClient = client? 'client=' + client : '';
    const queryIdentifier = identifier? 'identifier=' + identifier : '';
    const query = '?' + queryClient + queryIdentifier;

    let headers = {
        'client-id': clientId,
        'access-token': token
    };
    return axios.get(`${apiUrl}/chat${query}`, {headers});
}