import styled from 'styled-components';

export default styled.div`
    font-size: 18px;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 999;
    padding: 0px;

    .main-container {
        max-width: 1200px;
        margin: auto;
        display: flex;
        position: relative;
        padding: 15px;
        padding-left: 0px;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .balto-icon {
        width: 106px;
    }

    .sign-in {
        padding: 6px 10px;
        margin-top: 8px;
        margin-right: 5px;
        min-width: 202px;
        max-width: 202px;
        border: 1px solid #000;
        color: #000;
        font-size: 16px;
        text-align: center;
    }

    .right-container {
        position: absolute;
        right: 15px;
    }

    .right-container .profile-icon svg {
        color: #f2424cff;
        height: 100%;
    }

    .right-container .profile-icon {
        border: 2px solid #f2424cff;
        text-align: center;
        width: 40px;
        margin-top: 7px;
        margin-right: 20px;
        border-radius: 20px;
        height: 40px;
    }


    .simple-link-container {
        display: flex;
        padding-top: 15px;
    }

    .simple-link-container .contact-button {
        background-color: #005bec;
        color: #fff;
        border-radius: 5px;
        padding: 10px 25px;
        margin-left: 40px;
        margin-top: -15px;
        text-align: center;
    }

    .anchor-link, .simple-link a {
        color: #000;
    }

    .anchor-link:hover, .simple-link a:hover {
        text-decoration: none;
    }

    .simple-link {
        padding: 0px 15px;
    }

    .simple-link:hover {
        color: #777;
        transition: all 0.3s ease;
    }

    .contact-button:hover {
        color: #fff;
    }

    .menu-nav, .menu-button {
        display: none;
    }

    .menu-button {
        width: 40px;
        height: 40px;
        border: 1px solid #085CE0;
        padding-top: 6.5px;
        cursor: pointer;
        margin-top: 5px;
    }

    .menu-line {
        width: 20px;
        border-bottom: 3px solid blue;
        margin: auto;
        padding: 2px;
        transition: all .3s ease;
    }

    .menu-line:last-child {
        transition: 0s;
    }

    .close-menu .menu-line:last-child {
        display: none;
    }

    .close-menu .menu-line {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-transform-origin: 35%;
        transform-origin: 35%;
        padding: 0px;
        width: 30px;
        margin-left: 6px;
        margin-top: 3px;
    }

    .close-menu .menu-line:first-child {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg)!important;
        margin-top: 7.5px;
    }

    .menu-nav {
        list-style: none;
        padding: 15px;
        padding-top: 0px;
        margin-bottom : 0px;
    }

    .menu-nav .last-anchor {
        border-bottom: 0px;
        margin-top: 25px;
    }

    .menu-nav > li a {
        border-bottom: 1px solid #dedede;
        padding: 15px 0px;
        display: block;
    }

    .arrow {
        border: solid #999;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        position: absolute;
        right: 33px;
        margin-top: 10px;
    }

    .contact-message {
        padding: 15px;
        text-align: center;
        display: none;
    }

    .contact-message.error, .contact-message.success {
        display: block;
    }

    .contact-message.error {
        background-color: red;
        color: #fff;
        font-weight: bold;
        opacity: 0.6;
    }

    .contact-message.success {
        background-color: green;
        color: #fff;
        font-weight: bold;
        opacity: 0.6;
    }

    @media only screen and (max-width: 1100px) {
        padding: 0px;

        .home-icon {
            width: 60%;
        }

        .main-container {
            border-bottom: 1px solid #dedede;
        }

        .menu-button {
            display: block;
        }
        .show-menu {
            display: block;
        }

        .simple-link {
            padding: 0px;
        }

        .simple-link-container .contact-button {
            margin: 0px;
            margin-top: -15px;
        }
    }
`;