import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Style from './Style';
import Button from 'react-bootstrap/Button';
import config from '../../config/config.json';
import { getClientDesk, uploadAudio } from "../../helpers/getClientDesk.js";

class Web extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasStartedCapture: false,
            isClientInvalid: false,
            deskToken: '',
            deskId: 0,
            error: null
        };
        this.audioStream = null;
        this.audioIntervalRef = null;
        this.checkDeskToken = this.checkDeskToken.bind(this);
        this.captureAudio = this.captureAudio.bind(this);
        this.stopAudioCapture = this.stopAudioCapture.bind(this);
        this.saveAudioChunk = this.saveAudioChunk.bind(this);
    }

    componentDidMount() {
        this.checkDeskToken();
    }

    checkDeskToken() {
        const deskToken = window.location.pathname.substring(8);

        getClientDesk(deskToken)
            .then(res => {
                this.setState({
                    deskToken: deskToken,
                    deskId: res.data[0].id
                });
            })
            .catch(err => {
                this.setState({ isClientInvalid: true });
            });
    }

    async captureAudio() {
        if (this.state.hasStartedCapture) {
            this.stopAudioCapture();
            return;
        }

        this.setState({
            hasStartedCapture: true,
            error: null
        });
        
        try {
            // Solicita permissão para acessar o microfone e inicia o fluxo de áudio
            this.audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            console.log("Captura de áudio iniciada.");

            // Inicia a gravação contínua do áudio
            this.startRecording();

        } catch (error) {
            console.error("Erro ao acessar o microfone:", error);
            this.setState({
                hasStartedCapture: false,
                error: "Erro ao acessar o microfone"
            });
        }
    }

    startRecording() {
        // Configura um novo MediaRecorder e define o processamento de dados
        this.mediaRecorder = new MediaRecorder(this.audioStream, { mimeType: 'audio/webm' });

        this.mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
                this.saveAudioChunk(event.data);
            }
        };

        this.mediaRecorder.onstop = () => {
            // Recomeça a gravação imediatamente ao parar o atual
            if (this.state.hasStartedCapture) {
                this.startRecording();
            }
        };

        // Inicia a gravação e agenda a parada após 10 segundos
        this.mediaRecorder.start();
        setTimeout(() => {
            if (this.mediaRecorder.state === "recording") {
                this.mediaRecorder.stop();
            }
        }, 60000); // Duração de cada gravação: 1 minuto
    }

    stopAudioCapture() {
        this.setState({ hasStartedCapture: false });
        if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
            this.mediaRecorder.stop();
        }
        if (this.audioStream) {
            this.audioStream.getTracks().forEach(track => track.stop());
        }
        console.log("Captura de áudio parada.");
    }

    saveAudioChunk(blob) {
        uploadAudio(this.state.deskToken, blob, this.state.deskId);
    }

    render() {
        return (
            <Style>
                <Helmet>
                    <meta name="robots" content="noindex"/>
                </Helmet>
                {this.state.isClientInvalid ? (
                    <div className='first-container'>
                        <div className='invalid-token-container'>
                            <div className='invalid-token'>Token inválido</div>
                        </div>
                    </div>
                ) : (
                    <section className='first-container'>
                        <div className='identifier-container'>
                            {this.state.hasStartedCapture ? 
                                <div className="audio-visualizer">
                                    <div className="bar"></div>
                                    <div className="bar"></div>
                                    <div className="bar"></div>
                                    <div className="bar"></div>
                                    <div className="bar"></div>
                                </div>
                                :
                                <div className='audio-visualizer-mock'>
                                </div>
                            }

                            <Button
                                onClick={this.captureAudio}
                                className={`identifier-button ${this.state.hasStartedCapture ? 'recording' : ''}`}
                                type="submit"
                            >
                                <strong>{this.state.hasStartedCapture ? 'PARAR GRAVAÇÃO' : 'COMEÇAR GRAVAÇÃO'}</strong>
                            </Button>

                            <div className="powered-by">
                                <span className="cnpj"><i>powered by</i></span>
                                <span>BALTO</span>
                            </div>

                            {/* Exibir erro caso exista */}
                            {this.state.error && (
                                <div className="error-message">
                                    {this.state.error}
                                </div>
                            )}
                        </div>
                    </section>
                )}
            </Style>
        );
    }
}

export default Web;
