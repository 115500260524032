import axios from 'axios';
import config from  '../config/config.json';

export const getClient = (clientToken) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.get(`${apiUrl}/client?client-token=${clientToken}`);
}

export const usageLimit = (clientId, clientToken) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    let headers = {
        'client-id': clientId,
        'access-token': clientToken
    };

    return axios.get(`${apiUrl}/client/usage-limit`, {headers});
}

export const getClientProducts = (clientId, diagnosis) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/client/products`,
        {
            "diagnosis": diagnosis
        }, {
        'headers': {
            "client-id": clientId
        }});
}

export const getDiagnosis = (clientId, clientToken) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    let headers = {
        'client-id': clientId,
        'access-token': clientToken
    };

    return axios.get(`${apiUrl}/client/diagnosis`, {headers});
}

export const postDiagnosis = (clientId, clientToken, diagnosis, identifier) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/client/diagnosis`,
        {
            "diagnosis": diagnosis,
            "identifier": identifier
        }, {
        'headers': {
            "client-id": clientId,
            'access-token': clientToken
        }});
}

export const addClientChat = (clientId, clientToken, identifier, products, conditions, symptoms, chatId) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/client/add-chat`,
        {
            "identifier": identifier,
            "products": products,
            "conditions": conditions,
            "symptoms": symptoms,
            "chatId": chatId
        }, {
        'headers': {
            "client-id": clientId,
            'access-token': clientToken
        }});
}

export const getConditions = (clientId, clientToken, products) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/client/get-conditions`,
        {
            "products": products
        }, {
        'headers': {
            "client-id": clientId,
            'access-token': clientToken
        }});
}

export const getSymptoms = (clientId, clientToken, products, conditions) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/client/get-symptoms`,
        {
            "products": products,
            "conditions": conditions
        }, {
        'headers': {
            "client-id": clientId,
            'access-token': clientToken
        }});
}

export const getSymptomsFromOneCondition = (clientId, clientToken, conditions) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/client/get-symptoms-one-condition`,
        {
            "conditions": conditions
        }, {
        'headers': {
            "client-id": clientId,
            'access-token': clientToken
        }});
}

export const getBasket = (clientId, clientToken, conditions, symptoms, isAllSymptoms) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/client/get-basket`,
        {
            "symptoms": symptoms,
            "conditions": conditions,
            "isAllSymptoms": isAllSymptoms
        }, {
        'headers': {
            "client-id": clientId,
            'access-token': clientToken
        }});
}

export const getAnamnesisSymptoms = (clientId, clientToken, groupProductName) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/client/get-anamnesis-symptoms`,
        {
            "groupProductName": groupProductName
        }, {
        'headers': {
            "client-id": clientId,
            'access-token': clientToken
        }});
}

export const getBasketFromAnamnesis = (clientId, clientToken, chatId, symptoms, identifier, products) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/client/get-basket-from-anamnesis`,
        {
            "symptoms": symptoms,
            "chatId": chatId,
            "identifier": identifier,
            "products": products
        }, {
        'headers': {
            "client-id": clientId,
            'access-token': clientToken
        }});
}