import axios from 'axios';
import config from  '../config/config.json';

export const getMedicine = () => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.get(`${apiUrl}/medicine`);
}

export const setMedicine = (clientId, medicineId) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/medicine`,
        {
            "medicineId": medicineId
        }, {
        'headers': {
            "client-id": clientId
        }});
}

export const setSymptom = (conversationId, clientId, nextQuestionId, symptomId) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/medicine/symptom`,
        {
            "conversationId": conversationId,
            "nextQuestionId": nextQuestionId,
            "symptomId": symptomId
        }, {
        'headers': {
            "client-id": clientId
        }});
}

export const getInterchangeableProducts = (clientId, clientModule, conversationId, medicineType, therapeuticClass, productText) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/medicine/interchangeable-products`,
        {
            "conversationId": conversationId,
            "therapeuticClass": therapeuticClass,
            "productText": productText,
            "medicineType": medicineType
        }, {
        'headers': {
            "client-id": clientId,
            "Client-Module": clientModule
        }});
}

export const getCorrelatedProducts = (clientId, clientModule, conversationId, therapeuticClass) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/medicine/correlated-products`,
        {
            "conversationId": conversationId,
            "therapeuticClass": therapeuticClass
        }, {
        'headers': {
            "client-id": clientId,
            "Client-Module": clientModule
        }});
}
