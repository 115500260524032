import styled from 'styled-components';

export default styled.div`
    .first-container {
        background-image: linear-gradient(31.53deg,#f4f8ff 3.69%,#dbe9ff 78.33%,#3f88ff 165.68%);
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: center;
        height: 100vh;
        position: relative;
    }

    .invalid-token-container {
        position: absolute;
        bottom: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .invalid-token {
        color: #0077b8;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
    }

    .identifier-container {
        width: 100%;
        max-width: 1000px;
        position: absolute;
        padding: 20px;
        padding-top: 0px;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .identifier-button {
        max-width: 300px;
        width: 100%;
        margin: auto;
        display: block;
        background-color: #007DC5;
        border-radius: 19px;
        margin-bottom: 40px;
        padding: 12px;
    }

    .powered-by {
        margin-left: 5px;
        color: #0077b8;
        font-size: 16px;
        font-weight: bold;
        margin-top: 10px;
        text-align: center;
    }

    .powered-by .cnpj {
        font-size: 13px;
        color: #717171;
        margin-right: 6px;
        font-weight: initial;
    }


    /* Estilo do botão */
    .identifier-button {
        transition: all 0.3s ease;
    }

    /* Estilo do botão quando está gravando */
    .identifier-button.recording {
        background-color: #FF0000; /* Vermelho para indicar gravação */
        box-shadow: 0 0 15px rgba(255, 0, 0, 0.6);
        color: white;
        border: 0px;
    }

    /* Animação das barras do visualizador de áudio */
    .audio-visualizer {
        display: flex;
        gap: 4px;
        margin-bottom: 20px;
        height: 30px;
        align-items: center;
        justify-content: center;
    }

    .audio-visualizer-mock {
        height: 50px;
        display: block;
    }

    /* Estilo de cada barra */
    .bar {
        width: 5px;
        height: 100%;
        background-color: #007DC5;
        animation: bounce 1s infinite;
        display: inline-block;
        border-radius: 4px;
    }

    /* Animação de "subir e descer" das barras */
    .bar:nth-child(1) { animation-delay: 0s; }
    .bar:nth-child(2) { animation-delay: 0.2s; }
    .bar:nth-child(3) { animation-delay: 0.4s; }
    .bar:nth-child(4) { animation-delay: 0.6s; }
    .bar:nth-child(5) { animation-delay: 0.8s; }

    @keyframes bounce {
        0%, 100% {
            transform: scaleY(0.3);
        }
        50% {
            transform: scaleY(1);
        }
    }

    /* Mensagem de erro */
    .error-message {
        color: #FF0000; /* Vermelho para erro */
        font-size: 1em;
        margin-top: 10px;
        animation: fadeIn 0.5s ease-in-out;
    }

    /* Animação para a mensagem de erro */
    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }

`;