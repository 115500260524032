import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Web from './Web';
import Admin from './components/admin/admin';
import privacy from './components/privacy/privacy';
import bnpl from './components/bnpl/bnpl';
import home from './components/home/home';
import captureaudio from './components/captureaudio/captureaudio';
import terms from './components/terms/terms';
import primaryCare from './components/primaryCare/primaryCare';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ReactGA from "react-ga4";

ReactGA.initialize("G-0L5S03879J");

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/" exact={true} component={home} />
            <Route path="/cliente" component={Web} />
            <Route path="/balcao" component={captureaudio} />
            <Route path="/pagamento" component={bnpl} />
            <Route path="/admin" exact={true} component={Admin} />
            <Route path="/politica-de-privacidade" exact={true} component={privacy} />
            <Route path="/termos-de-uso" exact={true} component={terms} />
            <Route path="/atencao-primaria" component={primaryCare} />
        </Switch>
    </ BrowserRouter>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();