import axios from 'axios';
import config from  '../config/config.json';
const env = process.env.REACT_APP_ENV || 'development';
const apiUrl = config[env].REACT_APP_API_URL;

export const createCart = (clientToken, clientModule) => {
    return axios.post(`${apiUrl}/cart/create?client-token=${clientToken}`, {}, {
        'headers': {
            "Client-Module": clientModule
        }
    });
}

export const addProductToCart = (cartId, quantity, productId, clientToken, clientModule) => {
    const body = {
        cartId: cartId,
        cart: {
          "orderItems": [
              {
                 "id": productId,
                 "quantity": quantity,
                 "seller": "1"
              }
          ]
        }
    };

    return axios.post(`${apiUrl}/cart/add-product?client-token=${clientToken}`, body, {
        'headers': {
            "Client-Module": clientModule
        }
    });
}

export const bulkAddProductToCart = (cartId, orderItems, clientToken, clientModule) => {
    const body = {
        cartId: cartId,
        cart: {
          "orderItems": orderItems
        }
    };

    return axios.post(`${apiUrl}/cart/add-product?client-token=${clientToken}`, body, {
        'headers': {
            "Client-Module": clientModule
        }
    });
}

export const removeProductFromCart = (index, quantity, cartId, clientToken, clientModule) => {
    const body = {
        cartId: cartId,
        cart: {
          "orderItems": [
                {
                    "quantity": quantity,
                    "index": index
                }
            ]
        }
    };

    return axios.post(`${apiUrl}/cart/remove-product?client-token=${clientToken}`, body, {
        'headers': {
            "Client-Module": clientModule
        }
    });
}

export const bulkRemoveProductFromCart = (orderItems, cartId, clientToken, clientModule) => {
    const body = {
        cartId: cartId,
        cart: {
          "orderItems": orderItems
        }
    };

    return axios.post(`${apiUrl}/cart/remove-product?client-token=${clientToken}`, body, {
        'headers': {
            "Client-Module": clientModule
        }
    });
}

export const addCampaign = (cartId, clientToken, clientModule) => {
    const body = {
        cartId: cartId
    };

    return axios.post(`${apiUrl}/cart/add-campaign?client-token=${clientToken}`, body, {
        'headers': {
            "Client-Module": clientModule
        }
    });
}