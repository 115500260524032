import React, { Component } from 'react';
import Style from './Style';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { auth } from "../../helpers/auth.js";
import { listChats } from "../../helpers/listChats.js";
import { addFeedback } from "../../helpers/addFeedback.js";
import { Helmet } from "react-helmet";

const clientId = window.localStorage.getItem('client-id');
const diagnosisCorrect = {
    "left": "20px",
    "width": "300px",
    "fontSize": "22px",
    "bottom": "0",
};
const diagnosisWrong = {
    "right": "20px",
    "width": "300px",
    "fontSize": "22px",
    "bottom": "0",
    "backgroundColor": "red",
    "color": "#fff",
};

class Admin extends Component{
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            identifier: '',
            hasLoggedIn: false,
            openModal: false,
            openSecondModal: false,
            detailChatIndex: 0,
            diagnosisFeedback: false,
            observationFeedback: '',
            chats: []
        };
        this.loginSubmit = this.loginSubmit.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.searchIdentifier = this.searchIdentifier.bind(this);
        this.listChats = this.listChats.bind(this);
        this.detailChat = this.detailChat.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.diagnosisFeedback = this.diagnosisFeedback.bind(this);
        this.sendFeedback = this.sendFeedback.bind(this);
    }

    componentDidMount() {
        if (clientId)
            this.setState({ hasLoggedIn: true})
        this.listChats('', '');
    }

    loginSubmit(e) {
        e.preventDefault();
        let obj = {
            email: this.state.email,
            password: this.state.password
        };
        auth(obj)
            .then(res => {
                localStorage.setItem('client-id', res.data.id);
                localStorage.setItem('access-token', res.headers['access-token']);
            })
            .catch((err) => {
                console.log('Usuário ou senha não reconhecidos');
            });
    }

    inputChange(e, key) {
        this.setState({ [key]: e.target.value })
    }

    searchIdentifier(e) {
        e.preventDefault();
        let client = '';
        this.listChats(client, this.state.identifier);
    }

    listChats(client, identifier) {
        listChats(client, identifier)
            .then(res => {
                this.setState({ chats: res.data})
            });
    }

    detailChat(index) {
        this.setState({
            openModal: true,
            detailChatIndex: index
        })
    }

    handleModalClose() {
        this.setState({ openModal: false});
    }

    diagnosisFeedback(status) {
        this.handleModalClose();

        this.setState({
            diagnosisFeedback: status,
            openSecondModal: !status
        }, () => {
            if (status)
                this.sendFeedback();
        });
    }

    sendFeedback(){
        addFeedback(this.state.chats[this.state.detailChatIndex].id, this.state.diagnosisFeedback, this.state.observationFeedback)
            .then((res) => {
                this.setState({
                    openSecondModal: false,
                    observationFeedback: ''
                })
            });
    }

    render() {
        return (
            <Style>
                <Helmet>
                    <meta name="robots" content="noindex"/>
                </Helmet>
                <section className='admin-first-container'>
                    <div className={!this.state.hasLoggedIn? 'display-none' : 'list-chats-container'}>
                        <Form onSubmit={(e) => this.searchIdentifier(e)} className="search-identifier-form">
                            <Form.Group controlId="searchIdentifier">
                                <Form.Control type="text" className="search-identifier-input"
                                    onChange={(e) => this.inputChange(e, 'identifier')}
                                    autoFocus
                                    placeholder="Busque pelo identificador do paciente" />
                            </Form.Group>
                            <Button className="search-identifier-button" type="submit">Buscar</Button>
                        </Form>
                        <table className='chat-table'>
                            <thead>
                                <tr>
                                    <th>Data</th>
                                    <th>Identificador</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody className="list-group">
                                {this.state.chats.map((chat, index) => (
                                    <tr key={index} className="chat-item">
                                        <td>{chat.createdAt}</td>
                                        <td>{chat.identifier}</td>
                                        <td><Button onClick={() => this.detailChat(index)}>Ver informações do paciente</Button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {(this.state.chats.length > 0)?
                            <Modal
                                centered
                                size="lg"
                                dialogClassName="modal-90w"
                                aria-labelledby="contained-modal-title-vcenter"
                                show={this.state.openModal}
                                onHide={this.handleModalClose}>
                                <Modal.Header closeButton>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="exchanging-messages">
                                        {JSON.parse(this.state.chats[this.state.detailChatIndex].messages).map((message, index) =>
                                            <div id={"item-" + index} key={index} className={"message-pattern message-type-" + message.role}>
                                                {(message.role === 'user')?
                                                    <strong>Você:  </strong>
                                                    : <strong>Saúde 360:  </strong>}
                                                {message.content}
                                            </div>
                                        )}
                                    </div>
                                    <Button style={diagnosisCorrect} className='diagnosis-correct submit-form' onClick={() => this.diagnosisFeedback(true)} variant="primary">Diagnóstico Correto</Button>
                                    <Button style={diagnosisWrong} className='diagnosis-wrong submit-form' onClick={() => this.diagnosisFeedback(false)} variant="secundary">Diagnóstico Errado</Button>
                                </Modal.Body>
                                <Modal.Footer></Modal.Footer>
                            </Modal>
                            : ''
                        }
                        {(this.state.openSecondModal)?
                            <Modal
                                centered
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                show={this.state.openSecondModal}
                                onHide={this.handleModalClose}>
                                <Modal.Body>
                                    <div style={{'fontSize':'24px', 'textAlign': 'center'}}>Por que o diagnóstico sugerido não foi correto?</div>
                                    <textarea style={{'width': '100%'}} onChange={(e) => this.inputChange(e, 'observationFeedback')} rows={4} />
                                    <Button onClick={() => this.sendFeedback()} variant="primary">ENVIAR</Button>
                                </Modal.Body>
                                <Modal.Footer></Modal.Footer>
                            </Modal>
                            : ''
                        }
                    </div>
                    <div className={this.state.hasLoggedIn? 'display-none' : 'ended-chat-container'}>
                        <h2>Login</h2>
                        <Form onSubmit={(e) => this.loginSubmit(e)} className="identifier-form">
                            <Form.Group controlId="loginSubmit" className="identifier-input">
                                <Form.Control type="text" className="email-input"
                                    onChange={(e) => this.inputChange(e, 'email')}
                                    autoFocus
                                    placeholder="E-mail" />
                                <Form.Control type="password"
                                    onChange={(e) => this.inputChange(e, 'password')}
                                    placeholder="Senha" />
                            </Form.Group>
                            <Button className="identifier-button" type="submit">ENTRAR</Button>
                        </Form>
                    </div>
                </section>
            </Style>
        )
    }
}

export default Admin;