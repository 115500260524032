import React, { Component } from 'react';
import Style from './Style';
const clientId = window.localStorage.getItem('jet7-client-id');

class Terms extends Component{
    render() {
        return (
            <Style>
                <div className="main-container">
                    <h1>Termos de Uso</h1>
                    <br/>
                    
                    <div>
                        <h2 className="bold-text">SEÇÃO 1 - VISÃO GERAL</h2>
                        <br/>
                        A plataforma online BALTO trata-se de API com uso de inteligência artificial (IA), via encaminhamento de mensagem entre o usuário logado no site da Parceira e a plataforma BALTO. O contato do usuário para com a plataforma tem como finalidade primordial o conhecimento de sugestões de medicações para os sintomas informados. 
                        <br/>
                        <br/>
                        Trata-se, portanto, de ferramenta meramente informativa e sugestiva, baseada nos sintomas e diagnósticos indicados pelo próprio usuário na plataforma. Ao entrar em nossa plataforma e informar os sintomas e possíveis diagnósticos, você, usuário, concorda com os termos e condições que se seguem (“Termos de uso”, “Termos”), incluindo os termos e condições e políticas adicionais mencionados neste documento, mesmo que implementadas futuramente. Esses Termos de Uso se aplicam a todos os usuários que se utilizem da plataforma BALTO, incluindo, sem limitação, os usuários que são navegadores, fornecedores, clientes, e/ou contribuidores de conteúdo. 
                        <br/>
                        <br/>
                        A BALTO disponibiliza em seu acesso interno a possibilidade de compra da medicação pelo site da Parceira, possuindo, portanto, seus próprios termos de uso e política de privacidade. 
                        <br/>
                        <br/>
                        Por favor, leia esses termos cuidadosamente antes de acessar ou utilizar a nossa plataforma. Ao acessar ou usar qualquer parte da plataforma, você concorda com os Termos de Uso. Se você não concorda com todos os termos e condições aqui dispostas, então você não pode acessar quaisquer de seus serviços. Se esses Termos de Uso são considerados uma oferta, a aceitação é expressamente limitada a esse instrumento. 
                        <br/>
                        <br/>
                        Quaisquer novos recursos, conteúdos ou ferramentas que forem adicionados, editados ou alterados no aplicativo também serão informados nos Termos de Uso. 
                        <br/>
                        <br/>
                        Você pode revisar a versão mais atual dos Termos de Uso a qualquer momento nesta página. Reservamos o direito de atualizar, alterar ou trocar qualquer parte destes Termos de Uso ao publicar atualizações e/ou alterações na plataforma BALTO. É de responsabilidade do usuário verificar as alterações feitas nesta página periodicamente. Seu uso contínuo ou acesso ao aplicativo após a publicação de quaisquer alterações constitui aceitação de tais alterações.
                    </div>
                    
                    <br/>
                    <br/>
                    <br/>
                    
                    <div>
                        <h2 className="bold-text">SEÇÃO 2 – DA NATUREZA DO APLICATIVO</h2>
                        <br/>
                        O aplicativo é meio digital e auxiliar ao tratamento de sintomas e diagnósticos, sendo fornecido ao usuário sugestões de medicações para o seu quadro atual. 
                        <br/>
                        <br/>
                        A plataforma não solicita quaisquer dados pessoais, possuindo, entretanto, a necessidade de aceitação do presente termos para o uso total do dispositivo. Ao ter acesso ao aplicativo, o usuário poderá informar os sintomas a que está acometido, os quais serão coletados e armazenados em banco de dados pela plataforma e vinculados a um número de usuário, sem qualquer identificação deste. 
                        <br/>
                        <br/>
                        Quaisquer usos indevidos dos serviços disponibilizados no aplicativo BALTO e fora dos padrões estabelecidos, poderão incidir responsabilização civil e criminal.
                    </div>

                    <br/>
                    <br/>
                    <br/>
                    
                    <div>
                        <h2 className="bold-text">SEÇÃO 3 - TERMOS DO APLICATIVO</h2>
                        <br/>
                        Ao <strong>concordar</strong> com os Termos de Uso, o usuário fica ciente que:
                        <br/>
                        <br/>
                        I - confirma que é maior de idade em seu estado ou província de residência e possui plena capacidade civil.
                        <br/>
                        <br/>
                        II - concorda que não deve usar o aplicativo BALTO para qualquer fim ilegal ou não autorizado. 
                        <br/>
                        <br/>
                        III - está de acordo que não pode, ao usufruir deste serviço, violar quaisquer leis em sua jurisdição (incluindo, mas não limitado, a leis de direitos autorais). 
                        <br/>
                        <br/>
                        IV - não deve transmitir nenhum vírus ou qualquer código de natureza destrutiva. 
                        <br/>
                        <br/>
                        V- não pode utilizar o aplicativo BALTO para outros fins, senão com o objetivo meramente auxiliativo ao diagnóstico. 
                        <br/>
                        <br/>
                        VI - deve manter os dados pessoais contidos no Parceiro sempre atualizados, uma vez que, para o acesso à plataforma, o usuário deverá estar logado no site Parceiro.
                        <br/>
                        <br/>
                        VII - é responsável por todas as informações inseridas na plataforma, não sendo responsável a BALTO acerca de possíveis informações incorretas, falsas, de má-fé e sem relação com a finalidade da plataforma.
                        <br/>
                        <br/>
                        VIII – está ciente que a plataforma BALTO se utiliza dos anúncios de medicações de seu Parceiro, optando o usuário pelo redirecionamento ao site de compra ou não. Neste caso, a plataforma não possui nenhum domínio sobre estes, e a responsabilidade é totalmente do Parceiro anunciante. 
                        <br/>
                        <br/>
                        IX – está ciente e de acordo que quaisquer dados pessoais inseridos no momento do login do usuário ao site do Parceiro não serão compartilhados para com a plataforma BALTO, possuindo esta, tão somente, as informações coletadas dentro de sua própria plataforma. 
                        <br/>
                        <br/>
                        X - está ciente e de acordo que violar qualquer um dos itens dos termos tem como consequência a rescisão imediata dos Termos de Uso, o uso e acesso ao aplicativo, bem como outras civilmente e penalmente analisadas de acordo com o caso. 
                        <br/>
                        <br/>
                        XI - está ciente e de acordo que, quaisquer descumprimentos das obrigações e deveres acima indicadas, bem como a atuação por dolo, culpa, má-fé ou engano podem ser objeto de ação penal e ação civil.
                    </div>
                    
                    <br/>
                    <br/>

                    <div>
                        <h2 className="bold-text">SEÇÃO 4 - CONDIÇÕES GERAIS</h2>
                        <br/>
                        Reservamos o direito de recusar o serviço a qualquer pessoa por qualquer motivo a qualquer momento. 
                        <br/>
                        <br/>
                        Você concorda em não reproduzir, duplicar, copiar, vender, revender ou explorar qualquer parte do Serviço da Plataforma da BALTO, uso do Serviço, acesso ao Serviço, ou qualquer contato no aplicativo através do qual o serviço é fornecido, sem nossa permissão expressa e por escrito. 
                        <br/>
                        <br/>
                        Os títulos usados neste acordo são incluídos apenas por conveniência e não limitam ou afetam os Termos.
                    </div>

                    <br/>
                    <br/>

                    <div>
                        <h2 className="bold-text">SEÇÃO 5 - PRECISÃO, INTEGRIDADE E ATUALIZAÇÃO DAS INFORMAÇÕES</h2>
                        <br/>
                        A plataforma da BALTO não é responsável por informações que não sejam precisas, completas ou atuais, principalmente quando incluídas por usuários ou terceiros em má-fé. O material desse aplicativo é fornecido baseado nos estudos da equipe Balto, de modo a facilitar os estudos de diagnóstico e medicações dos usuários. O compartilhamento indevido das imagens é de responsabilidade do usuário.
                    </div>

                    <br/>
                    <br/>

                    <div>
                        <h2 className="bold-text">SEÇÃO 6 - MODIFICAÇÕES DO SERVIÇO E PREÇOS</h2>
                        <br/>
                        Reservamos o direito de, a qualquer momento, modificar ou descontinuar o Serviço (ou qualquer parte ou conteúdo do mesmo) sem notificação em qualquer momento ao usuário.
                    </div>

                    <br/>
                    <br/>

                    <div>
                        <h2 className="bold-text">SEÇÃO 7 - PRODUTOS OU SERVIÇOS</h2>
                        <br/>
                        Os serviços serão entregues sob a ótica dos profissionais e da inteligência artificial (IA), para fins de facilitação e auxílio na melhora dos sintomas, não possuindo nenhuma obrigação nesta relação. A plataforma BALTO garante a qualidade de seus serviços e entende atender as expectativas de seus usuários, dentro das informações em língua portuguesa dispostas pelo usuário no aplicativo, não cabendo, sob nenhuma hipótese, cobranças sob o produto ou serviço. Quaisquer reclamações neste sentido serão consideradas nulas.  
                        <br/>
                        <br/>
                        Como dito, trata-se de plataforma digital de mero auxílio e facilitação. A plataforma não substitui o profissional médico e, ao persistirem os sintomas, o médico deverá ser consultado. O serviço da BALTO não é responsável por medicações e/ou tratamentos equivocados, respondendo a plataforma de acordo com o informado pelo próprio usuário, sendo, portanto, a responsabilidade totalmente do terceiro, no caso, o cliente ou responsável legal. 
                        <br/>
                        <br/>
                        O usuário possui responsabilidade legal, quando do desvio de informações e/ou dados, respondendo a todos mediante amplo processo legal criminal e cível, ao contrário do informado pela parte adversa.
                    </div>

                    <br/>
                    <br/>

                    <div>
                        <h2 className="bold-text">SEÇÃO 8 - LINKS DE TERCEIROS</h2>
                        <br/>
                        Certos produtos, conteúdos e serviços disponíveis pelo nosso aplicativo podem incluir material de terceiro.  
                        <br/>
                        <br/>
                        Os links constantes nessa plataforma podem te direcionar para o site de nosso Parceiro, facilitando a compra de medicamentos, mediante login e senha. Não nos responsabilizamos por examinar ou avaliar o conteúdo do Parceiro e não garantimos e nem temos obrigação ou responsabilidade por quaisquer materiais ou sites de terceiros, ou por quaisquer outros materiais, produtos ou serviços de terceiros. 
                        <br/>
                        <br/>
                        Não somos responsáveis por quaisquer danos ou prejuízos relacionados com a compra ou uso de mercadorias, serviços, recursos, conteúdo, ou quaisquer outras transações feitas em conexão com quaisquer sites de terceiros. Por favor, revise com cuidado as políticas e práticas de terceiros e certifique-se que você as entende antes de efetuar qualquer transação. As queixas, reclamações, preocupações ou questões relativas a produtos de terceiros devem ser direcionadas ao terceiro. 
                        <br/>
                        <br/>
                        A plataforma BALTO é um meio meramente facilitador e de domínio próprio. Quaisquer parcerias ou sites de terceiros possuem suas próprias responsabilidades, não se adentrando a BALTO em nenhuma destas obrigações e responsabilidades.
                    </div>

                    <br/>
                    <br/>

                    <div>
                        <h2 className="bold-text">SEÇÃO 9 - INFORMAÇÕES PESSOAIS</h2>
                        <br/>
                        O tratamento de suas informações pessoais através da nossa plataforma é regido pelo presente Termos de Uso, considerando que nenhum dado pessoal é coletado e que toda e qualquer informação coletada não identifica nenhum usuário.
                    </div>

                    <br/>
                    <br/>

                    <div>
                        <h2 className="bold-text">SEÇÃO 10 - ERROS, IMPRECISÕES E OMISSÕES</h2>
                        <br/>
                        Ocasionalmente, pode haver informações no aplicativo ou no serviço com erros tipográficos, imprecisões ou omissões que possam relacionar-se a descrições de produtos, preços, promoções, ofertas e prazos. Reservamos o direito de corrigir quaisquer erros, imprecisões ou omissões, e de alterar ou atualizar informações ou cancelar caso qualquer informação.
                    </div>

                    <br/>
                    <br/>

                    <div>
                        <h2 className="bold-text">SEÇÃO 11 - USOS PROIBIDOS</h2>
                        <br/>
                        Além de outras proibições, conforme estabelecido nos Termos de Uso, queda-se proibido o uso da plataforma para: (a) fins ilícitos; (b) solicitar outras pessoas a realizar ou participar de quaisquer atos ilícitos; (c) violar quaisquer regulamentos internacionais, provinciais, estaduais ou federais, regras, leis ou regulamentos locais; (d) infringir ou violar nossos direitos de propriedade intelectual ou os direitos de propriedade intelectual de terceiros; (e) para assediar, abusar, insultar, danificar, difamar, caluniar, depreciar, intimidar ou discriminar com base em gênero, orientação sexual, religião, etnia, raça, idade, nacionalidade ou deficiência; (f) apresentar informações falsas ou enganosas; (g) fazer o envio ou transmitir vírus ou qualquer outro tipo de código malicioso que será ou poderá ser utilizado para afetar a funcionalidade ou operação do serviço ou de qualquer site relacionado, outros sites, ou da Internet; (h) coletar ou rastrear as informações pessoais de outras pessoas; (i) para enviar spam, phishing, pharm, pretext, spider, crawl, ou scrape; (j) para fins obscenos ou imorais; ou (k) para interferir ou contornar os recursos de segurança do serviço ou de qualquer site relacionado, outros sites, ou da Internet; (l) uso para outros fins, senão o de estudo e planejamento; (m) fazer-se passar por terceiro. Reservamos o direito de rescindir o seu uso do serviço relacionado por violar qualquer um dos usos proibidos.
                        <br/>
                        <br/>
                        O seu uso, de forma incorreta, proibida ou que gerem riscos será de responsabilidade única e exclusiva da parte que causou, seja de forma criminal, seja de forma cível.
                    </div>

                    <br/>
                    <br/>

                    <div>
                        <h2 className="bold-text">SEÇÃO 12 - ISENÇÃO DE RESPONSABILIDADE DE GARANTIAS; LIMITAÇÃO DE RESPONSABILIDADE</h2>
                        <br/>
                        Nós não garantimos, representamos ou justificamos que o seu uso do nosso serviço será pontual, seguro, sem erros ou sem interrupções. 
                        <br/>
                        <br/>
                        Você concorda que, de tempos em tempos, podemos remover o serviço por períodos indefinidos ou cancelar a qualquer momento, sem envio de notificação, bem como realizar atualizações e melhorias na plataforma, com a retirada deste da intranet, por tempo indefinido. 
                        <br/>
                        <br/>
                        Nós não nos responsabilizamos por quaisquer danos gerados pelos redirecionamentos dos sites ou páginas para fora da plataforma BALTO. Neste caso, a plataforma não possui nenhum domínio sobre estes, e a responsabilidade é totalmente do terceiro anunciante. 
                        <br/>
                        <br/>
                        Você, maior de idade e capaz, se responsabiliza pela disponibilidade de informações de menores sob sua guarda junto à plataforma BALTO.
                    </div>

                    <br/>
                    <br/>

                    <div>
                        <h2 className="bold-text">SEÇÃO 13 - INDENIZAÇÃO</h2>
                        <br/>
                        Você concorda em indenizar, defender e isentar a BALTO e nossos subsidiários, afiliados, parceiros, funcionários, diretores, agentes, contratados, licenciantes, prestadores de serviços, subcontratados, fornecedores, estagiários e funcionários, de qualquer reclamação ou demanda, incluindo honorários de advogados, por quaisquer terceiros devido à violação destes Termos de uso ou aos documentos que incorporam por referência ou à violação de qualquer lei ou os direitos de um terceiro.
                    </div>

                    <br/>
                    <br/>

                    <div>
                        <h2 className="bold-text">SEÇÃO 14 - INDEPENDÊNCIA</h2>
                        <br/>
                        No caso de qualquer disposição destes Termos de Uso ser considerada ilegal, nula ou ineficaz, tal disposição deve, contudo, ser aplicável até ao limite máximo permitido pela lei aplicável, e a porção inexequível será considerada separada desse Termo de Uso. Tal determinação não prejudica a validade e aplicabilidade de quaisquer outras disposições restantes.
                    </div>

                    <br/>
                    <br/>

                    <div>
                        <h2 className="bold-text">SEÇÃO 15 - RESCISÃO</h2>
                        <br/>
                        As obrigações e responsabilidades das partes incorridas antes da data de rescisão devem continuar após a referida data para todos os efeitos. 
                        <br/>
                        <br/>
                        Este Termo está em vigor, a menos que ocorra a sua rescisão por opção sua ou do administrador da plataforma. Você pode optar pela rescisão deste Termo a qualquer momento ou quando você deixar de usar o nosso aplicativo. 
                        <br/>
                        <br/>
                        Se em nosso critério exclusivo, você não cumprir com qualquer termo ou disposição destes Termos de Uso, o aplicativo também poderá rescindir este contrato a qualquer momento sem aviso prévio e também poderemos lhe negar acesso a nossos serviços.
                    </div>

                    <br/>
                    <br/>

                    <div>
                        <h2 className="bold-text">SEÇÃO 16 - ACORDO INTEGRAL</h2>
                        <br/>
                        Caso não exerçamos ou executemos qualquer direito ou disposição destes Termos de Uso, isso não constituirá renúncia do referido direito ou disposição. 
                        <br/>
                        <br/>
                        Quaisquer ambiguidades na interpretação destes Termos de Uso não devem ser interpretadas contra a parte que os redigiu.
                    </div>

                    <br/>
                    <br/>

                    <div>
                        <h2 className="bold-text">SEÇÃO 17 - LEGISLAÇÃO APLICÁVEL</h2>
                        <br/>
                        O presente Termos de Uso e quaisquer atualizações futuras e políticas dispostas no aplicativo são regidas e interpretadas seguindo os preceitos da Lei nº 13.709/2018 – Lei Geral de Proteção de Dados (“LGPD”).
                    </div>

                    <br/>
                    <br/>

                    <div>
                        <h2 className="bold-text">SEÇÃO 18 - ALTERAÇÕES DO TERMO DE USO</h2>
                        <br/>
                        Você pode rever a versão mais atual dos Termos de Uso a qualquer momento nessa página. 
                        <br/>
                        <br/>
                        Reservamos o direito, a nosso critério, de atualizar, modificar ou substituir qualquer parte destes Termos de Uso ao publicar atualizações e alterações no nosso site. É sua responsabilidade verificar nosso site periodicamente. Seu uso contínuo ou acesso ao nosso aplicativo ou ao serviço após a publicação de quaisquer alterações a estes Termos de Uso constituem aceitação dessas alterações.
                    </div>

                    <br/>
                    <br/>

                    <div>
                        <h2 className="bold-text">SEÇÃO 19 - INFORMAÇÕES DE CONTATO</h2>
                        <br/>
                        As perguntas sobre os Termos de Uso devem ser enviadas para nós, através do e-mail <a className="dpo-contact" href="mailto:dpo@saude-360.com" title="">dpo@saude-360.com</a>.
                        <br/>
                        <br/>
                        O uso dos nossos conteúdos e serviços pressupõe a aceitação destes Termos de Uso.
                    </div>

                    <br/>
                    <br/>
                    Revisado em: 06/10/2023

                </div>
            </Style>
        )
    }
}

export default Terms;