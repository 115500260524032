import axios from 'axios';
import config from  '../config/config.json';

export const getFirstQuestion = () => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.get(`${apiUrl}/question`);
}

export const getNextQuestion = (alternatives, alternativeId, nextQuestionId, conversationId, clientId, clientModule, identifier) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/question`,
        {
            "alternativeId": alternativeId,
            "nextQuestionId": nextQuestionId,
            "conversationId": conversationId,
            "alternatives": alternatives,
            "identifier": identifier
        }, {
        'headers': {
            "client-id": clientId,
            "Client-Module": clientModule
        }});
}

export const getProducts = (conversationId, clientId, clientModule) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/question/products`,
        {
            "conversationId": conversationId
        }, {
        'headers': {
            "client-id": clientId,
            "Client-Module": clientModule
        }});
}

export const getSecondaryProducts = (conversationId, clientModule) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/question/secondary-products`,
        {
            "conversationId": conversationId
        }, {
        'headers': {
            "Client-Module": clientModule
        }});
}

export const isUrgentSymptom = (conversationId) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/question/is-urgent-symptom`,
        {
            "conversationId": conversationId
        });
}

export const jumpAllQuestions = (conversationId, clientModule) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/question/jump-all-questions`,
        {
            "conversationId": conversationId
        }, {
        'headers': {
            "Client-Module": clientModule
        }});
}