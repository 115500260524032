import styled from 'styled-components';

export default styled.div`
    .main-container {
        background-image: linear-gradient(31.53deg,#f4f8ff 3.69%,#dbe9ff 78.33%,#3f88ff 165.68%);
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    .invalid-token-container {
        position: absolute;
        bottom: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .invalid-token {
        color: #0077b8;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
    }

    .logo-container {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 12px;
        opacity: 0.8;
    }

    .powered-by-container {
        display: flex;
    }

    .cnpj {
        font-size: 13px;
        color: #717171;
        font-weight: initial;
        margin-bottom: -7px;
    }

    .identifier-container {
        width: 100%;
        max-width: 1000px;
        position: absolute;
        padding: 20px;
        padding-top: 0px;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .display-none {
        display: none !important;
    }

    .checkbox-privacy-policy {
        margin-bottom: 25px;
    }

    .identifier-button {
        max-width: 300px;
        width: 100%;
        margin: auto;
        display: block;
    }

    .second-container, .third-container, .fourth-container, .fifth-container {
        padding: 15px;
    }

    .jump-container {
        border-bottom: 1px solid #a4b0d3;
        padding-bottom: 10px;
        margin-bottom: 5px;
    }

    .jump-button {
        font-size: 15px;
        cursor: pointer;
        color: #0000009c;
        margin-left: auto;
        margin-right: 0;
        display: table;
        box-shadow: 1px 1px 1px #95abeb;
        border: 1px solid #b4c4f1;
        padding: 2px 5px;
    }

    .question {
        font-size: 20px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 30px
    }

    .symptom-input input {
        font-size: 18px;
    }

    .symptom-list {
        float: left;
        max-width: 100%;
    }

    .symptom-option {
        border: 1px solid #d5d5d5;
        padding: 5px 20px;
        margin: 10px 0px;
        color: #0077b8;
        background-color: #fff;
        font-size: 18px;
        box-shadow: 3px 3px 5px #d5d5d5;
        width: fit-content;
        border-radius: 10px;
        cursor: pointer;
        float: left;
        margin-right: 10px;
    }

    .option {
        border: 1px solid #b4c4f1;
        padding: 5px 20px;
        margin: 10px 0px;
        color: #0077b8;
        background-color: #fff;
        font-size: 18px;
        box-shadow: 1px 1px 2px #b4c4f1;
        cursor: pointer;
        margin-right: 10px;
        text-align: center;
    }

    .multiple-choice-container {
        border: 1px solid #b4c4f1;
        padding: 10px 17px;
        margin: 10px 0px;
        color: #0077b8;
        background-color: #fff;
        font-size: 18px;
        box-shadow: 1px 1px 2px #b4c4f1;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .loading {
        position: absolute;
        top: calc(50% - 68px);
        left: calc(50% - 37px);
        height: 136px !important;
        width: 74px !important;
    }

    .diagnostics-title {
        font-size: 23px;
        font-weight: bold;
        margin-bottom: 30px;
    }

    .diagnosis-text-container {
        padding: 7px;
        background-color: #ffffff;
        font-size: 18px;
        margin-bottom: 10px;
        position: relative;
    }

    .diagnosis-description {
        padding: 0px 7px 10px;
        font-size: 17px;
        color: #585858;
        margin-bottom: 10px;
        background-color: #ffffff;
        margin-top: -9px;
    }

    .diagnosis-text-container i {
        position: absolute;
        right: 15px;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .diagnosis-container {
        box-shadow: 1px 1px 5px 1px #b4c4f169;
    }

    .alert-searh-doctor {
        background-color: #fff4d2d1;
        padding: 7px;
        margin-top: 25px;
        color: #484848;
        font-size: 15px;
    }

    .alert-urgent-diagnosis-title {
        background-color: #f77e7e;
        padding: 7px;
        margin-top: 25px;
        color: #484848;
        font-size: 17px;
    }

    .alert-urgent-diagnosis {
        background-color: #f77e7e63;
        padding: 7px;
        margin-top: 25px;
        color: #484848;
        font-size: 15px;
    }

    .see-drugs {
        position: absolute;
        bottom: 15px;
        width: calc(100% - 30px);
        left: 0;
        right: 0;
        max-width: none;
        margin: auto;
    }

    .multiple-choice-send {
        position: fixed;
        bottom: 15px;
        width: calc(100% - 30px);
        left: 0;
        right: 0;
        max-width: none;
        margin: auto;
    }

    .multiple-choice-checkbox input {
        width: 20px;
        height: 20px;
        margin-right: 7px;
    }

    .multiple-choice-container:last-of-type {
        margin-bottom: 50px;
    }

    .fourth-container .list-drug-title {
        background-color: #fff;
        padding: 10px 20px;
    }

    .list-drug-title {
        font-size: 20px;
    }

    .product-container {
        background-color: #fff;
    }

    .product-type {
        text-align: center;
        font-size: 20px;
    }

    i.fa.fa-medkit {
        color: #005bec;
    }

    i.fa.fa-leaf {
        color: green;
    }

    i.fa.fa-stethoscope {
        color: red;
    }

    .table thead th {
        border-bottom: 0px;
    }

    tbody tr:first-child {
        background-color: #dee2e6;
        font-weight: bold;
    }

    tbody tr:first-child td {
        border: 0px;
    }

    .product-container td {
        vertical-align: middle !important;
    }

    .odd-product-container .product-container, .odd-product-container .list-drug-title {
        background-color: #c1daff;
    }

    .striped-product-container {
        margin-bottom: 20px;
        border: 1px solid #005bec;
    }

    .product-container.table {
        margin-bottom: 0;
    }

    .product-container-secondary {
        background-color: #fff;
        margin-bottom: 0px;
    }

    .fourth-container {
        padding-bottom: 60px;
    }

    .cross-sell-title, .up-sell-title {
        font-size: 20px;
        background-color: #fff;
        padding: 10px 20px 5px 20px;
        margin: 30px 0px 0px;
        font-weight: bold;
    }

    .up-sell-title {
        margin: 0px;
    }

    .secondary-products-container {
        border: 3px solid #FFAB40FF;
        margin-top: 60px;
    }

    .checkout-button-primary-care {
        margin: auto;
        display: block;
        text-align: center;
        background-color: #007bff;
        color: #fff;
        border-radius: 4px;
        padding: 10px;
        margin-top: 30px;
    }

    .continue-buying {
        background-color: #fff;
        z-index: 99;
        max-width: unset;
        width: 100%;
        color: #007bff;
        border: 1px solid #007bff;
        margin-top: 15px;
    }

    .continue-payment {
        background-color: #007DC5;
        position: fixed;
        bottom: 2px;
        z-index: 99;
        max-width: unset;
        width: 100%;
        left: 0;
    }

    .fifth-container-message {
        text-align: center;
        font-size: 24px;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        padding: 10px;
    }

    .combo-container {
        padding: 15px;
    }

    .combo-image {
        display: block;
        max-width: 100%;
        max-height: 185px;
        width: auto;
        margin: auto;
        object-fit: cover;
    }

    .combo-text-inntroduction {
        font-size: 17px;
        margin-bottom: 20px;
        font-weight: bold;
    }

    .combo-products {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .combo-price {
        font-size: 26px;
        color: #0077b8;
        font-weight: 600;
        margin: 20px 0px 10px;
        text-align: center;
    }

    .explanation-container {
        background-color: #fff4d2d1;
        padding: 5px;
        margin-bottom: 20px;
    }

    .combo-products-explanation {
        margin-bottom: 5px;
        font-size: 18px;
    }

    .continue-buying {
        margin-bottom: 50px;
    }
`;