import React, { Component } from 'react';
import Style from './Style';
import 'font-awesome/css/font-awesome.min.css';
import WebHeader from '../webHeader/webHeader';
import WebFooter from '../webFooter/webFooter';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import emailSVG from '../../email.svg';
import dashboardFirstContainer from '../../Shutterstock_651777256.png';
import graficoRiscoOportunidadeImg from '../../Shutterstock_2072813561.png';
import graficoRiscoOperadoraImg from '../../Shutterstock_1940595922.png';
import first_second_container from '../../first_second_container.png';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import paraEspresaImg from '../../Shutterstock_2101040086.png';
import checklistDataSharingImg from '../../checklistDataSharing.png';
import consentImg from '../../consent.png';
import sendEmailsListImg from '../../sendEmailsList.png';
import discountsImg from '../../Shutterstock_1280457310.png';
import inteligenceImg from '../../Shutterstock_7760254483.png';
import electronicHealthImg from '../../iStock-1280292736-1.png';
import EpocaNegocios from '../../EpocaNegocios.svg';
import consumidorModerno from '../../consumidorModerno.png';
import Abrafarma from '../../Abrafarma.png';
import spacemoney from '../../spacemoney.jpeg';
import chatVideo from '../../chatVideo.mp4';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Helmet } from "react-helmet";
import moment from 'moment'
import 'moment/min/locales';
import config from  '../../config/config.json';
import emailjs from '@emailjs/browser';
moment.locale('pt-BR');

class Home extends Component{
    constructor(props) {
        super(props);
        // contact status 0 is the initial value, 1 for received contact, and -1 if we received any error
        this.state = {
            contactStatus: 0,
            showHelmet: false
        };
        this.postEvent = this.postEvent.bind(this);
        this.removeMessageDelay = this.removeMessageDelay.bind(this);
    }

    // componentDidMount() {
    //     if (window.location.href.indexOf("saude-360") > 0)
    //         this.setState({ showHelmet: true });
    // }

    postEvent(event) {
        event.preventDefault();
        const env = process.env.REACT_APP_ENV || 'development';
        const SERVICE_ID = config[env].emailjs.SERVICE_ID;
        const TEMPLATE_ID = config[env].emailjs.TEMPLATE_ID;
        const PUBLIC_KEY = config[env].emailjs.PUBLIC_KEY;

        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, event.target, PUBLIC_KEY)
        .then((result) => {
            this.setState({
                contactStatus: 1
            }, function() {
                this.removeMessageDelay();
            });
        }, (error) => {
            this.setState({
                contactStatus: -1
            }, function() {
                this.removeMessageDelay();
            })
        });
    }

    removeMessageDelay() {
        setTimeout(function() {
            this.setState({contactStatus: 0})
        }.bind(this), 4000)
    }


    // <h1>A nova forma de se relacionar com os pacientes</h1>
    // <p>Inteligência artificial conversacional para maior conexão com pacientes. Mais saúde. Mais pacientes. Mais engajamento.</p>

    render() {
        return (
            <Style>
                {(this.state.showHelmet)?
                    <Helmet>
                        <meta name="robots" content="noindex"/>
                    </Helmet>
                    : <div></div>
                }
                <div>
                    <WebHeader showContactMessage={this.state.contactStatus}/>
                    <section className="home-first-container">
                        <div className="home-first-container-main">
                            <div className="home-first-left-container">
                                <h1>Ultrapersonalize o atendimento dos seus clientes</h1>
                                <p>Inteligência artificial para melhor tratamento, maior conversão e fidelização.</p>
                                <AnchorLink offset='78' href='#contact'>
                                    <button className="home-first-container-contact-button">
                                        Contato
                                        <img
                                            className="home-email-icon"
                                            src={emailSVG}
                                            alt="Teste grátis" />
                                    </button>
                                </AnchorLink>
                            </div>
                            <div className="home-first-right-container">
                                <img
                                    className="home-dashboard-first-container"
                                    src={dashboardFirstContainer}
                                    alt="Toda a saúde em um só lugar" />
                            </div>
                        </div>
                    </section>

                    <section id='for-brokers' className="home-first-second-container">
                        <div className="home-first-second-sub-container">
                            <div className="home-first-second-container-right">
                                <h2>Sua farmácia está deixando de faturar <br/> metade do seu real potencial</h2>
                                <p>Farmacêuticos experientes têm ticket médio 2x maior que farmacêuticos inexperientes.</p>
                                <br/>
                                <p>Treinamentos levam 8 meses para darem resultados e, com turnover anual de 50%, treinamentos são pouco eficientes.</p>
                                <br/>
                                <p>Com 20.000 SKUs numa farmácia, é impossível que o farmacêutico lembre tudo de cabeça.</p>
                                <AnchorLink offset='78' href='#contact'>
                                    <button className="home-first-second-container-contact-button">
                                        Conheça a solução
                                        <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                    </button>
                                </AnchorLink>
                            </div>
                            <div className="home-first-second-container-left video-container">
                                <img
                                    className="home-dashboard-first-second-container"
                                    src={first_second_container}
                                    alt="Farmacêutico experiênte têm ticket médio 2x maior que o inexperiente" />
                            </div>
                        </div>
                    </section>

                    <section id='for-brokers'>
                        <div className="home-second-container">
                            <div className="home-second-container-left home-web video-container">
                                <video className="balto-video-player" muted loop autoPlay width="640" height="660" >
                                      <source src={chatVideo} type="video/mp4"/>
                                </video>
                            </div>
                            <div className="home-second-container-right">
                                <h2>Tratamento de saúde personalizado <br/> para seus clientes</h2>
                                <p>Suba a barra do atendimento do seu time de farmácia e transforme a experiência de tratamento e compra dos seus clientes.</p>
                                <br/>
                                <p>Mantenha o relacionamento com o seus clientes pós-atendimento gerando maior fidelidade.</p>
                                <br/>
                                <p>Entregue maior ferramental para os seus farmacêuticos e suba de nível o atendimento dos seus clientes.</p>
                            </div>
                            <div className="home-second-container-left home-mobile video-container">
                                <video className="balto-video-player" muted loop autoPlay width="640" height="660" >
                                      <source src={chatVideo} type="video/mp4"/>
                                </video>
                            </div>
                        </div>
                    </section>

                    <section id='for-companies' className="home-third-container">
                        <div className="home-third-container-main">
                            <div className="home-third-container-left">
                                <h2>Potencialize a receita da sua farmácia</h2>
                                <p className="home-text-under-h2">Use dados de saúde para oferecer uma cesta de compras relevante para seu cliente.</p>
                                <p className="home-text-under-h2">Aumente a conversão dos clientes com atendimento personalizado.</p>
                                <p className="home-text-under-h2">Conheça mais do perfil epidemiológico da região que você atua.</p>
                                <AnchorLink offset='78' href='#contact'>
                                    <Button className="home-submit-form" type="submit">
                                        Saiba mais
                                        <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                    </Button>
                                </AnchorLink>
                            </div>
                            <div className="home-third-container-right">
                                <img
                                    src={paraEspresaImg}
                                    alt="Imagem com dashboards e descontos em planos de saúde" />
                            </div>
                        </div>
                    </section>

                    <section className="home-block-group">
                        <div className="home-block-group-container-main">
                            <h2>Resultados comprovados para o seu negócio</h2>
                            <div className="home-block-group-container">
                                <span>
                                    <p className="home-text-number-under-h2">+30%</p>
                                    <p className="home-text-under-h2">Aumento do ticket médio</p>
                                </span>
                                <span>
                                    <p className="home-text-number-under-h2">+10k</p>
                                    <p className="home-text-under-h2">Iterações</p>
                                </span>
                                <span>
                                    <p className="home-text-number-under-h2">+15%</p>
                                    <p className="home-text-under-h2">Aumento da conversão</p>
                                </span>
                                <span>
                                    <p className="home-text-number-under-h2">100</p>
                                    <p className="home-text-under-h2">NPS</p>
                                </span>
                            </div>
                        </div>
                    </section>

                    <section id='for-companies' className="home-fourth-container">
                        <div className="home-fourth-container-main">
                            <div className="home-fourth-container-left">
                                <h2>O que dizem da Balto</h2>
                                <Swiper
                                    slidesPerView={1}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Pagination, Navigation]}
                                    className="mySwiper home-swiper"
                                    >

                                    <SwiperSlide>
                                        <div className="swipe-text-message">"A Balto será muito importante para nos ajudar na missão de diminuir o índice de automedicação e facilitar o atendimento aos nossos clientes"</div>
                                        <div className="swipe-text-person">João Albery Andrade Dias Neto<br/>Diretor de Serviços Farmacêuticos da Pague Menos</div>
                                    </SwiperSlide>
                                    
                                    <SwiperSlide>
                                        <div className="swipe-text-message">"Foi fácil de implantar e me ajudou com uma das minhas maiores dificuldades, que é manter o time sempre treinado e atualizado em relação ao que vender"</div>
                                        <div className="swipe-text-person">Filipe Rodrigues<br/>Bifarma</div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swipe-text-message">
                                            <span>"Eu já estava tomando um remédio para dor de cabeça, mas não melhorava.
                                            Depois do assistente virtual Balto tomei o remédio que foi certeiro para eu ficar melhor."</span>
                                        </div>
                                        <div className="swipe-text-person">Fernando Dias<br/>Cliente da Bifarma</div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swipe-text-message">
                                            <span>"Amei conhecer a Balto! <br/> Com ela me senti mais acolhida e confiante para saber qual remédio tomar."</span>
                                        </div>
                                        <div className="swipe-text-person">Juliana De Souza Pereira<br/>Cliente da Pague Menos</div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </section>

                    <section id='for-companies' className="home-fifth-container">
                        <div className="home-fifth-container-main">
                            <h2>Na mídia</h2>
                            <div className="home-fifth-container-flex">
                                <div className="card-midia">
                                    <a style={{backgroundImage: `url(${EpocaNegocios})`}} href="https://epocanegocios.globo.com/empresas/noticia/2023/07/pague-menos-cria-servico-com-ia-para-orientar-clientes-contra-a-automedicacao.ghtml" target="_blank"></a>
                                </div>
                                <div className="card-midia">
                                    <a style={{backgroundImage: `url(${consumidorModerno})`}} href="https://www.abrafarma.com.br/noticias/pague-menos-cria-servico-com-ia-para-orientar-clientes-contra-a-automedicacao" target="_blank"></a>
                                </div>
                                <div className="card-midia">
                                    <a style={{backgroundImage: `url(${Abrafarma})`}} href="https://consumidormoderno.com.br/2023/07/07/ia-pague-menos-atendimento/" target="_blank"></a>
                                </div>
                                <div className="card-midia">
                                    <a style={{backgroundImage: `url(${spacemoney})`}} href="https://www.spacemoney.com.br/geral/pague-menos-pgmn3-implementainteligencia-artificial/195129/" target="_blank"></a>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id='contact' className="home-sixth-container">
                        <div className="home-sixth-container-main">
                            <div className="home-sixth-container-left">
                                <h2>Mal podemos esperar para ouvir suas ideias!</h2>
                            </div>
                            <div className="home-sixth-container-right">
                                <Form onSubmit={(e) => this.postEvent(e)}>
                                    <Form.Group controlId="userName" className="home-inline-input home-inline-input-left">
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control type="text" placeholder="Seu nome"
                                            name="userName"
                                            required />
                                    </Form.Group>

                                    <Form.Group controlId="companyName" className="home-inline-input home-inline-input-right">
                                        <Form.Label>Nome da empresa</Form.Label>
                                        <Form.Control type="text" placeholder="Nome da sua empresa"
                                            name="companyName"
                                            required />
                                    </Form.Group>

                                    <Form.Group controlId="email" className="home-inline-input home-inline-input-left">
                                        <Form.Label>E-mail</Form.Label>
                                        <Form.Control type="text" placeholder="Seu e-mail profissional"
                                            name="email"
                                            required />
                                    </Form.Group>

                                    <Form.Group controlId="phoneNumber" className="home-inline-input home-inline-input-right">
                                        <Form.Label>Telefone</Form.Label>
                                        <Form.Control type="text" placeholder="(11) 99999-9999"
                                            name="phoneNumber"
                                            required />
                                    </Form.Group>

                                    <Form.Group controlId="howCanWeHelp.ControlTextarea1">
                                        <Form.Label>Como podemos te ajudar?</Form.Label>
                                        <Form.Control as="textarea" rows="3"
                                            name="howCanWeHelp"
                                            required />
                                    </Form.Group>
                                    <Button className="home-submit-form" type="submit">Enviar</Button>
                                </Form>
                            </div>
                        </div>
                    </section>
                    <WebFooter />
                </div>
            </Style>
        )
    }
}

export default Home;