import axios from 'axios';
import config from  '../config/config.json';

export const getClientDesk = (clientToken) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    let headers = {
        'access-token': clientToken
    };

    return axios.get(`${apiUrl}/client-desk`, {headers});
}

export const uploadAudio = (clientToken, audioFile, clientDeskId) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    
    const formData = new FormData();

    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const timestamp = `${year}${month}${day}${hours}${minutes}${seconds}`;

    const fileName = `${clientDeskId}_${timestamp}.webm`;

    formData.append('audio', audioFile, fileName);

    let headers = {
        'access-token': clientToken,
        'Content-Type': 'multipart/form-data'
    };

    return axios.post(`${apiUrl}/client-desk/upload-audio`, formData, { headers });
}