import React, { Component, useState } from 'react';
import Style from './Style';
import ReactLoading from 'react-loading';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import ReactGA from "react-ga4";
import { getClient } from "../../helpers/getClient.js";
import 'font-awesome/css/font-awesome.min.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { createCart, addProductToCart, removeProductFromCart, bulkAddProductToCart, bulkRemoveProductFromCart } from "../../helpers/manageCart.js";
import { getFirstQuestion, getNextQuestion, getProducts, getSecondaryProducts, isUrgentSymptom, jumpAllQuestions } from "../../helpers/getQuestions.js";
import { getMedicine, setMedicine, setSymptom, getInterchangeableProducts, getCorrelatedProducts } from "../../helpers/medicine.js";
import { Helmet } from "react-helmet";
import comboImage from '../../combo-image.png';

class primaryCare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            clientToken: '',
            clientId: 0,
            clientLogoOne: '',
            clientLogoTwo: '',
            clientModule: '',
            isClientCustomerFacing: false,
            isClientInvalid: false,
            hasConsent: false,
            checkedPrivacy: false,
            questionNumber: 1,
            symptomsList: [],
            filteredSymptomsList: [],
            nextQuestion: '',
            alternatives: [],
            diagnostics: [],
            productsList: [],
            cartList: {},
            cartId: '',
            isDiagnosisUrgent: false,
            conversationId: null,
            showMedication: true,
            showSchedulePage: false,
            secondaryProductsList: [],
            cartTicket: 0,
            isMultipleChoices: false,
            multipleAlternativeObj: {},
            questionIndex: 0,
            combo: {},
            comboInCart: 0,
            firstAlternativesList: [],
            symptomsAlternativeList: [],
            hasStartedWithAMedicine: false,
            firstMedicineTypeSelected: '',
            firstMedicineTextSelected: '',
            firstMedicineTherapeuticalClassSelected: '',
            identifier: '',
            mockWama: false,
            showExams: false,
            exams: []
        };
        this.checkClientToken = this.checkClientToken.bind(this);
        this.inputPrivacyChange = this.inputPrivacyChange.bind(this);
        this.validateConsent = this.validateConsent.bind(this);
        this.searchFirstSymptom = this.searchFirstSymptom.bind(this);
        this.setFirstSymptom = this.setFirstSymptom.bind(this);
        this.getNextQuestion = this.getNextQuestion.bind(this);
        this.getProductsList = this.getProductsList.bind(this);
        this.handleCart = this.handleCart.bind(this);
        this.manageScheduleAppointment = this.manageScheduleAppointment.bind(this);
        this.manageCartTicket = this.manageCartTicket.bind(this);
        this.inputAlternativeChange = this.inputAlternativeChange.bind(this);
        this.jumpQuestions = this.jumpQuestions.bind(this);
        this.handleComboCart = this.handleComboCart.bind(this);
        this.swipeProduct = this.swipeProduct.bind(this);
        this.checkIfIsSymptomOrProduct = this.checkIfIsSymptomOrProduct.bind(this);
        this.urlIdentifier = this.urlIdentifier.bind(this);
        this.urlCart = this.urlCart.bind(this);
        this.seeExams = this.seeExams.bind(this);
    }

    componentDidMount() {
        this.checkClientToken();
    }

    checkClientToken() {
        const clientToken = window.location.pathname.substring(18);
        let symptoms = [];

        getClient(clientToken)
            .then(res => {

                ReactGA.event({
                    category: "IA em arvore",
                    action: "IA - token cliente validado",
                    label: `IA - token cliente validado - ${res.data[0].id}`
                });

                this.setState({
                    clientToken: clientToken,
                    clientId: res.data[0].id,
                    clientLogoOne: (res.data[0].logoOne)? require(`../../${res.data[0].logoOne}`) : '',
                    clientLogoTwo: (res.data[0].logoTwo)? require(`../../${res.data[0].logoTwo}`) : '',
                    clientModule: res.data[0].searchProductsModule,
                    isClientCustomerFacing: res.data[0].isCustomerFacing
                }, () => {
                    this.urlIdentifier();
                    this.urlCart();
                });
                return getFirstQuestion();
            })
            .then((res) => {
                symptoms = res.data;

                return getMedicine();
            })
            .then((res) => {
                let firstAlternativesList = symptoms.concat(res.data);

                this.setState({
                    firstAlternativesList: firstAlternativesList,
                    filteredSymptomsList: firstAlternativesList,
                    symptomsList: firstAlternativesList
                });
            })
            .catch(err => {
                this.setState({ isClientInvalid: true });
            });
     }

     urlIdentifier() {
        if (window.location.search.includes('identifier=')) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const identifier = urlParams.get('identifier');
            this.setState({
                identifier: identifier
            });
        }
     }

     urlCart() {
        if (window.location.search.includes('cartId=')) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const cartId = urlParams.get('cartId');
            this.setState({
                cartId: cartId
            });
        }
     }

    inputPrivacyChange(e) {
        this.setState({ checkedPrivacy: e.target.checked });
    }

    validateConsent() {
        if (this.state.checkedPrivacy)
            this.setState({ hasConsent: true });
    }

    searchFirstSymptom(e) {
        let finalArray = [];
        for (let i = 0; i < this.state.firstAlternativesList.length; i++) {
            if (this.state.firstAlternativesList[i].alternative.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0)
                finalArray.push(this.state.firstAlternativesList[i]);
        }
        this.setState({ filteredSymptomsList: finalArray })
    }

    setFirstSymptom(index) {
        this.setState({
            questionNumber: 2,
            isLoading: true,
            questionIndex: 1
        }, () => {
            this.getNextQuestion(index, true);
        });
    }

    getNextQuestion(index, isFirstQuestion, _nextQuestionId, _alternativeId) {
        if (_nextQuestionId == 0) {
            this.setState({
                isLoading: true,
                questionIndex: this.state.questionIndex + 1,
                symptomsAlternativeList: [],
                questionNumber: this.state.questionIndex + 1
            }, () => {
                getInterchangeableProducts(this.state.clientId, this.state.clientModule, this.state.conversationId, this.state.firstMedicineTypeSelected, this.state.firstMedicineTherapeuticalClassSelected, this.state.firstMedicineTextSelected)
                    .then(res => {
                        this.setState({
                            secondaryProductsList: res.data,
                            isLoading: false
                        });
                    });
            });
        }
        else if (typeof index !== 'undefined') {
            let nextQuestionId;
            let alternativeId;

            let questionIndex = this.state.questionIndex;
            let symptomId;

            if (_nextQuestionId || _nextQuestionId == 0) {
                symptomId = this.state.symptomsAlternativeList[index].symptomId

                nextQuestionId = _nextQuestionId;
                alternativeId = _alternativeId;
            }
            else {
                nextQuestionId = (isFirstQuestion)? this.state.filteredSymptomsList[index].nextQuestionId : this.state.alternatives[index].nextQuestionId;
                alternativeId = (isFirstQuestion)? this.state.filteredSymptomsList[index].alternativeId : this.state.alternatives[index].alternativeId;
            }

            ReactGA.event({
                category: "IA em arvore",
                action: "IA - selecionou alternativa",
                label: `IA - selecionou alternativa ${alternativeId} da pergunta ${nextQuestionId}`
            });

            this.setState({
                isLoading: true,
                questionIndex: questionIndex + 1,
                symptomsAlternativeList: [],
                questionNumber: this.state.questionNumber + 1
            }, () => {
                if (this.state.hasStartedWithAMedicine && questionIndex < 1) {
                    setSymptom(this.state.conversationId, this.state.clientId, nextQuestionId, symptomId)
                        .then(res => {
                            if (res.data.question) {
                                let isMultipleChoices = (res.data.question[0].isMultipleChoices)? true : false;

                                let multipleAlternativeObj = {}
                                for (let i = 0; i < res.data.question.length; i++) {
                                    multipleAlternativeObj[i] = false;
                                }

                                this.setState({
                                    conversationId: res.data.conversationId,
                                    nextQuestion: res.data.question[0].question,
                                    alternatives: res.data.question,
                                    isLoading: false,
                                    isMultipleChoices: isMultipleChoices,
                                    multipleAlternativeObj: multipleAlternativeObj
                                });
                            }
                            else {
                                ReactGA.event({
                                    category: "IA em arvore",
                                    action: "IA - chegou em diagnóstico",
                                    label: `IA - chegou em diagnóstico ${res.toString()}`
                                });

                                if (this.state.isClientCustomerFacing) {
                                    isUrgentSymptom(this.state.conversationId)
                                        .then(_res => {
                                            if (_res.data) {
                                                this.setState({
                                                    showMedication: false,
                                                    diagnostics: res.data,
                                                    isLoading: false
                                                });
                                            }
                                            else {
                                                this.setState({
                                                    diagnostics: res.data,
                                                    isLoading: false
                                                });
                                            }
                                        });
                                }
                                else {
                                    this.setState({
                                        diagnostics: res.data,
                                        isLoading: false
                                    });
                                }
                            }
                        });
                }
                else {
                    getNextQuestion([], alternativeId, nextQuestionId, this.state.conversationId, this.state.clientId, this.state.clientModule, this.state.identifier)
                        .then(res => {
                            if (res.data.question) {
                                let isMultipleChoices = (res.data.question[0].isMultipleChoices)? true : false;

                                let multipleAlternativeObj = {}
                                for (let i = 0; i < res.data.question.length; i++) {
                                    multipleAlternativeObj[i] = false;
                                }

                                this.setState({
                                    conversationId: res.data.conversationId,
                                    nextQuestion: res.data.question[0].question,
                                    alternatives: res.data.question,
                                    isLoading: false,
                                    isMultipleChoices: isMultipleChoices,
                                    multipleAlternativeObj: multipleAlternativeObj
                                });
                            }
                            else {
                                ReactGA.event({
                                    category: "IA em arvore",
                                    action: "IA - chegou em diagnóstico",
                                    label: `IA - chegou em diagnóstico ${res.toString()}`
                                });

                                if (this.state.isClientCustomerFacing) {
                                    isUrgentSymptom(this.state.conversationId)
                                        .then(_res => {
                                            if (_res.data) {
                                                this.setState({
                                                    showMedication: false,
                                                    diagnostics: res.data,
                                                    isLoading: false
                                                });
                                            }
                                            else {
                                                this.setState({
                                                    diagnostics: res.data,
                                                    isLoading: false
                                                });
                                            }
                                        });
                                }
                                else {
                                    this.setState({
                                        diagnostics: res.data,
                                        isLoading: false
                                    });
                                }
                            }
                        });
                }
            });


        }
        else {

            ReactGA.event({
                category: "IA em arvore",
                action: "IA - selecionou alternativa",
                label: `IA - selecionou múltiplas alternativas`
            });

            let nextQuestionId
            let alternativesArray = [];

            for (let i = 0; i < this.state.alternatives.length; i++) {
                if (this.state.multipleAlternativeObj[i]) {
                    if (alternativesArray.length == 0)
                        nextQuestionId = this.state.alternatives[i].nextQuestionId;

                    alternativesArray.push(this.state.alternatives[i].alternativeId)
                }
            }

            if (alternativesArray.length != 0) {
                this.setState({
                    isLoading: true
                }, () => {
                    getNextQuestion(alternativesArray, '', nextQuestionId, this.state.conversationId, this.state.clientId, this.state.clientModule, this.state.identifier)
                        .then(res => {
                            if (res.data.question) {
                                let isMultipleChoices = (res.data.question[0].isMultipleChoices)? true : false;

                                let multipleAlternativeObj = {}
                                for (let i = 0; i < res.data.question.length; i++) {
                                    multipleAlternativeObj[i] = false;
                                }

                                this.setState({
                                    conversationId: res.data.conversationId,
                                    nextQuestion: res.data.question[0].question,
                                    alternatives: res.data.question,
                                    isLoading: false,
                                    isMultipleChoices: isMultipleChoices,
                                    multipleAlternativeObj: multipleAlternativeObj
                                });
                            }
                            else {
                                ReactGA.event({
                                    category: "IA em arvore",
                                    action: "IA - chegou em diagnóstico",
                                    label: `IA - chegou em diagnóstico ${res.toString()}`
                                });

                                if (this.state.isClientCustomerFacing) {
                                    isUrgentSymptom(this.state.conversationId)
                                        .then(_res => {
                                            if (_res.data) {
                                                this.setState({
                                                    showMedication: false,
                                                    diagnostics: res.data,
                                                    isLoading: false
                                                });
                                            }
                                            else {
                                                this.setState({
                                                    diagnostics: res.data,
                                                    isLoading: false
                                                });
                                            }
                                        });
                                }
                                else {
                                    this.setState({
                                        diagnostics: res.data,
                                        isLoading: false
                                    });
                                }
                            }
                        });
                });
            }
        }
    }

    getProductsList() {

        ReactGA.event({
            category: "IA em arvore",
            action: "IA - selecionou ver medicamentos",
            label: `IA - selecionou ver medicamentos`
        });

        this.setState({ isLoading: true },
            () => {
                if (this.state.hasStartedWithAMedicine) {
                    getCorrelatedProducts(this.state.clientId, this.state.clientModule, this.state.conversationId, this.state.firstMedicineTherapeuticalClassSelected)
                        .then(res => {
                            this.setState({
                                productsList: res.data,
                                isLoading: false
                            });
                        });
                    getInterchangeableProducts(this.state.clientId, this.state.clientModule, this.state.conversationId, this.state.firstMedicineTypeSelected, this.state.firstMedicineTherapeuticalClassSelected, this.state.firstMedicineTextSelected)
                        .then(res => {
                            this.setState({
                                secondaryProductsList: res.data
                            });
                        });
                }
                else {
                    getProducts(this.state.conversationId, this.state.clientId, this.state.clientModule)
                        .then(res => {
                            if (res.data.price) {
                                this.setState({
                                    combo: res.data,
                                    isLoading: false
                                })
                            }
                            else {
                                this.setState({
                                    productsList: res.data,
                                    isLoading: false
                                });
                            }
                        });

                    // getSecondaryProducts(this.state.conversationId, this.state.clientModule)
                    //     .then(res => {
                    //         this.setState({
                    //             secondaryProductsList: res.data
                    //         });
                    //     });
                }

            });
        if (this.state.clientModule != '' && this.state.cartId == ''){
            createCart(this.state.clientToken, this.state.clientModule)
                .then(_res => {
                    this.setState({ cartId: _res.data.orderFormId })
                });
        }
    }

    handleCart(index, productPrice, productId, addingProduct) {
        let vtexIndex = 0;
        let _cartList = this.state.cartList;
        let cartListObj = {
            quantity: 0,
            cartIndex: 0
        }

        if (addingProduct) {
            ReactGA.event({
                category: "IA em arvore",
                action: "IA - adicionou produto no carrinho",
                label: `IA - id do produto ${productId} - id do carrinho ${this.state.cartId}`
            });

            ReactGA.event({
                category: "IA em arvore",
                action: `IA - adicionou produto no carrinho na posição ${index}`,
                label: `IA - posição ${index}`
            });

            if (_cartList[index]) {
                cartListObj.quantity = _cartList[index].quantity + 1;
                cartListObj.cartIndex = _cartList[index].cartIndex;
            }
            else {
                cartListObj.quantity = 1;
                cartListObj.cartIndex = Object.keys(_cartList).length;
            }

            this.manageCartTicket(productPrice, true)

            addProductToCart(this.state.cartId, cartListObj.quantity, productId, this.state.clientToken, this.state.clientModule)
                .then(res => {
                    _cartList[index] = cartListObj;
                    this.setState({ 'cartList': _cartList });
                });
        }
        else {

            ReactGA.event({
                category: "IA em arvore",
                action: "IA - removeu produto no carrinho",
                label: `IA - id do produto ${productId} - id do carrinho ${this.state.cartId}`
            });

            this.manageCartTicket(productPrice, false)

            removeProductFromCart(_cartList[index].cartIndex, _cartList[index].quantity - 1, this.state.cartId, this.state.clientToken, this.state.clientModule)
                .then(res => {
                    _cartList[index].quantity = _cartList[index].quantity - 1;
                    this.setState({ 'cartList': _cartList });
                });
        }
    }

    manageScheduleAppointment() {

        ReactGA.event({
            category: "IA em arvore",
            action: "IA - clicou agendar consulta",
            label: "IA - clicou agendar consulta"
        });

        if (this.state.clientModule === 'vtex.paguemenos')
            window.open("https://semprebem.ciclic.com.br/?utm_source=balto", "_blank");
        else
            this.setState({ showSchedulePage: true })
    }

    manageCartTicket(price, isAdding) {
        let cartTicket = (isAdding)? this.state.cartTicket + price : this.state.cartTicket - price;
        this.setState({ cartTicket: cartTicket });
    }

    inputAlternativeChange(index) {
        let multipleAlternativeObj = this.state.multipleAlternativeObj;
        multipleAlternativeObj[index] = !this.state.multipleAlternativeObj[index];

        this.setState({ multipleAlternativeObj: multipleAlternativeObj });
    }

    jumpQuestions() {
        ReactGA.event({
            category: "IA em arvore",
            action: `IA - clicou pular tudo (indice ${this.state.questionIndex})`
        });

        if (this.state.questionNumber <= 3) {
            this.setState({
                isLoading: true
            }, () => {
                jumpAllQuestions(this.state.conversationId, this.state.clientModule)
                    .then(res => {
                        this.setState({
                            productsList: res.data,
                            isLoading: false,
                            symptomsAlternativeList: [],
                            alternatives: []
                        });
                    });
                if (this.state.hasStartedWithAMedicine) {
                    getInterchangeableProducts(this.state.clientId, this.state.clientModule, this.state.conversationId, this.state.firstMedicineTypeSelected, this.state.firstMedicineTherapeuticalClassSelected, this.state.firstMedicineTextSelected)
                        .then(res => {
                            this.setState({
                                secondaryProductsList: res.data,
                                isLoading: false
                            });
                        });
                }
            });
        }
        else {
            this.setState({
                isLoading: true
            }, () => {
                if (this.state.hasStartedWithAMedicine && this.state.questionNumber == 1) {
                    getInterchangeableProducts(this.state.clientId, this.state.clientModule, this.state.conversationId, this.state.firstMedicineTypeSelected, this.state.firstMedicineTherapeuticalClassSelected, this.state.firstMedicineTextSelected)
                        .then(res => {
                            this.setState({
                                secondaryProductsList: res.data,
                                isLoading: false
                            });
                        });
                }
                else {
                    getNextQuestion([], 'NULL', '', this.state.conversationId, this.state.clientId, this.state.clientModule, this.state.identifier)
                        .then(res => {
                            ReactGA.event({
                                category: "IA em arvore",
                                action: "IA - chegou em diagnóstico",
                                label: `IA - chegou em diagnóstico ${res.toString()}`
                            });

                            if (this.state.isClientCustomerFacing) {
                                isUrgentSymptom(this.state.conversationId)
                                    .then(_res => {
                                        if (_res.data) {
                                            this.setState({
                                                showMedication: false,
                                                diagnostics: res.data,
                                                isLoading: false
                                            });
                                        }
                                        else {
                                            this.setState({
                                                diagnostics: res.data,
                                                isLoading: false
                                            });
                                        }
                                    });
                            }
                            else {
                                this.setState({
                                    diagnostics: res.data,
                                    isLoading: false
                                });
                            }
                        });
                }
            });
        }

    }

    handleComboCart(isAdding) {
        let fetchArray = [];
        let orderItems = [];

        if (isAdding) {
            ReactGA.event({
                category: "IA em arvore",
                action: "IA - adicionou combo no carrinho"
            });

            for (let i = 0; i < this.state.combo.skus.length; i++) {
                let obj = {
                    "id": this.state.combo.skus[i],
                    "quantity": this.state.comboInCart + 1,
                    "seller": "1"
                };
                orderItems.push(obj);
            }

            bulkAddProductToCart(this.state.cartId, orderItems, this.state.clientToken, this.state.clientModule)
                .then(res => {
                    this.setState({ comboInCart: this.state.comboInCart + 1 });
                });
        }
        else {
            ReactGA.event({
                category: "IA em arvore",
                action: "IA - removeu combo no carrinho"
            });

            for (let i = 0; i < this.state.combo.skus.length; i++) {
                let obj = {
                    "quantity": this.state.comboInCart - 1,
                    "index": i
                };
                orderItems.push(obj);
            }

            bulkRemoveProductFromCart(orderItems, this.state.cartId, this.state.clientToken, this.state.clientModule)
                .then(res => {
                    this.setState({ comboInCart: this.state.comboInCart - 1 });
                });
        }
    }

    swipeProduct(obj, isPrimary, carousselLineIndex) {
        let primaryText = isPrimary? 'primario' : 'secundario';

        ReactGA.event({
            category: "IA conversacional",
            action: `IA - swipe produto ${primaryText} na linha ${carousselLineIndex} indice ${obj.activeIndex}`
        });
    }

    checkIfIsSymptomOrProduct(index) {
        if (this.state.filteredSymptomsList[index].type == 'therapeuticClass'
            || this.state.filteredSymptomsList[index].type == 'activeIngredient'
            || this.state.filteredSymptomsList[index].type == 'productName') {
            ReactGA.event({
                category: "IA em arvore",
                action: "IA - selecionou primeira alternativa como produto"
            });

            this.setState({
                isLoading: true,
                questionIndex: this.state.questionIndex + 1,
                questionNumber: this.state.questionNumber + 1
            }, () => {
                return setMedicine(this.state.clientId, this.state.filteredSymptomsList[index].id)
                    .then(res => {
                        this.setState({
                            isLoading: false,
                            conversationId: res.data.conversationId,
                            hasStartedWithAMedicine: true,
                            alternatives: [],
                            symptomsAlternativeList: res.data.symptoms,
                            firstMedicineTypeSelected: this.state.filteredSymptomsList[index].type,
                            firstMedicineTextSelected: this.state.filteredSymptomsList[index].alternative,
                            firstMedicineTherapeuticalClassSelected: this.state.filteredSymptomsList[index].therapeuticClass
                        });
                    })
            });

        }
        else {
            ReactGA.event({
                category: "IA em arvore",
                action: "IA - selecionou primeira alternativa como sintoma"
            });

            this.setState({
                questionNumber: 1,
                isLoading: true,
                questionIndex: 0
            }, () => {
                this.getNextQuestion(index, true);
            });
        }
    }

    seeExams() {
        this.setState({
            showExams: true,
            exams: ['Hemoglobina Glicada A1c']
        });
    }

    render() {
        return (
            <Style>
                <Helmet>
                    <meta name="robots" content="noindex"/>
                </Helmet>
                {this.state.isClientInvalid?
                    <div className='main-container'>
                        <div className='invalid-token-container'>
                            <div className='invalid-token'>Token inválido</div>
                        </div>
                        <div className="logo-container">
                            <div className="powered-by-container">
                                <div className="powered-by">
                                    <span className="cnpj">Powered by</span>
                                    <span>BALTO</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    : 
                    <section className='main-container'>
                        <div className={(!this.state.hasConsent)? 'identifier-container': 'display-none'}>
                            <h2>Esta é uma anamnese farmacêutica* e não substitui uma consulta médica.</h2>
                            <div className='explanation-anamnese'>*coleta de dados sobre o paciente através de perguntas.</div>
                            <div className='checkbox-privacy-policy'>
                                <Form.Check 
                                    required
                                    onChange={(e) => this.inputPrivacyChange(e)}
                                    value={this.state.checkedPrivacy}
                                    type='checkbox'
                                    id='checkbox'
                                />
                                <div className='terms-of-use-text'>Eu concordo com os <a target="_blank" href="/termos-de-uso">termos de uso</a> e desejo continuar.</div>
                            </div>
                            <Button onClick={() => this.validateConsent()} className="identifier-button" type="submit"><strong>CONTINUAR</strong></Button>
                            <div className="powered-by">
                                <span className="cnpj"><i>powered by</i></span>
                                <span>BALTO</span>
                            </div>
                        </div>

                        <div>
                            <div className={(this.state.hasConsent)? 'display-none' : 'logo-container'}>
                                <div className="powered-by-container">
                                    <img className="clinic-farma" src={this.state.clientLogoOne} />
                                </div>
                                <div>
                                    <img src={this.state.clientLogoTwo} />
                                </div>
                            </div>
                        </div>

                        <div className={(this.state.hasConsent && this.state.questionNumber === 1 && this.state.symptomsAlternativeList.length == 0)? 'second-container': 'display-none'}>
                            <div className='question'>
                                Qual sintoma que mais te incomoda? Ou busque por medicamento.
                            </div>
                            <Form.Group className="symptom-input">
                                <Form.Control type="text"
                                    onChange={(e) => this.searchFirstSymptom(e)}
                                    placeholder="Ex: Dor de cabeça"
                                    autoFocus
                                    name="symptom" />
                            </Form.Group>

                            <div className='symptom-list'>
                                {this.state.filteredSymptomsList.map((symptom, index) =>
                                    <div onClick={() => this.checkIfIsSymptomOrProduct(index)} key={index} className='symptom-option'>{symptom.alternative}</div>
                                )}
                                <br/>
                            </div>
                        </div>

                        {(this.state.symptomsAlternativeList.length > 0 && this.state.alternatives.length == 0 && !this.state.isLoading && this.state.diagnostics.length == 0 && this.state.secondaryProductsList.length == 0 && this.state.productsList.length == 0)?
                            <div className='second-container'>
                                <div className='jump-container'>
                                    <div onClick={() => this.jumpQuestions()} className='jump-button'>Pular tudo >></div>
                                </div>
                                <div className='question'>Quais dos sintomas abaixo você possui?</div>
                                <div className='alternatives'>
                                    {
                                        this.state.symptomsAlternativeList.map((alternative, index) =>
                                        <div onClick={() => this.getNextQuestion(index, false, alternative.nextQuestionId, alternative.alternativeId)} key={index} className='option'>{alternative.symptom}</div>
                                    )}
                                        <div onClick={() => this.setState({ symptomsAlternativeList: [], filteredSymptomsList: this.state.symptomsList })} className='option'>Ver todos os sintomas</div>
                                </div>
                            </div>
                            :
                            <div></div>
                        }

                        <ReactLoading className={(this.state.isLoading)? "loading" : "display-none"} type={"spinningBubbles"} color={'#0077b8'} height={'20%'} width={'20%'} />
                        {(this.state.alternatives.length > 0 && !this.state.isLoading)?
                            <div className={(this.state.hasConsent && this.state.questionNumber > 1 && this.state.diagnostics.length === 0)? 'third-container': 'display-none'}>
                                <div className='jump-container'>
                                    <div onClick={() => this.jumpQuestions()} className='jump-button'>Pular tudo >></div>
                                </div>
                                
                                <div className='question'>{this.state.nextQuestion}</div>
                                <div className='alternatives'>
                                    {
                                        (this.state.isMultipleChoices)?
                                            <div>
                                                {this.state.alternatives.map((alternative, index) =>
                                                    <div onClick={() => this.inputAlternativeChange(index)} key={index} className='multiple-choice-container'>
                                                        <Form.Check 
                                                            className='multiple-choice-checkbox'
                                                            onChange={(e) => this.inputAlternativeChange(e)}
                                                            checked={this.state.multipleAlternativeObj[index]}
                                                            type='checkbox'
                                                        />
                                                        <div className='multiple-choice-text'>{alternative.alternative}</div>
                                                    </div>
                                                )}
                                                <Button onClick={() => this.getNextQuestion()} className="multiple-choice-send" type="submit"><strong>ENVIAR</strong></Button>
                                            </div>
                                        :
                                            this.state.alternatives.map((alternative, index) =>
                                                <div onClick={() => this.getNextQuestion(index, false)} key={index} className='option'>{alternative.alternative}</div>
                                            )
                                    }
                                </div>
                            </div>
                            :
                            <div></div>
                        }
                        {(this.state.diagnostics.length > 0 && this.state.productsList.length === 0 && this.state.secondaryProductsList.length === 0 && this.state.isLoading == false && !this.state.showSchedulePage && !this.state.combo.price && !this.state.showExams)?
                            <div className='third-container'>
                                <div className='diagnostics-title'>Com base nas suas respostas, você pode ter os seguintes condições:</div>
                                <div className='diagnostics'>
                                    {this.state.diagnostics.map((diagnosis, index) =>
                                        <div key={index} className='diagnosis-container'>
                                            <div className='diagnosis-text-container'>
                                                <div className='diagnosis-text'>{diagnosis}</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='alert-searh-doctor'>
                                    As informações acima não substituem uma consulta médica.
                                </div>
                                {(this.state.isDiagnosisUrgent)?
                                    <div>
                                        <div class="alert-urgent-diagnosis-title"><strong>Recomendação: </strong>consulta médica urgente</div>
                                        <div class="alert-urgent-diagnosis">De acordo com as informações obtidas, sugerims ajuda médica o quanto antes para melhor investigação e tratamento adequado.</div>
                                    </div>
                                    : <div>
                                        {(this.state.showMedication)?
                                            <div>
                                                <div className='alert-searh-doctor'>
                                                    O uso de medicação pode trazer riscos, dentre eles alergias e eventos adversos. Procure sempre o médico e o farmacêutico. Leia a bula. Se persistirem os sintomas, o médico deverá ser consultado.
                                                </div>
                                                <Button onClick={() => this.getProductsList()} className="see-drugs" type="submit"><strong>VER MEDICAMENTOS</strong></Button>
                                            </div>
                                            : 
                                            (this.state.mockWama)?
                                                <Button onClick={() => this.seeExams()} className="see-drugs" type="submit"><strong>VER EXAMES</strong></Button>
                                                :
                                                <Button onClick={() => this.manageScheduleAppointment()} className="see-drugs" type="submit"><strong>AGENDAR CONSULTA</strong></Button>
                                            
                                        }
                                    </div>
                                }
                            </div>
                            : <div></div>
                        }
                        {(this.state.showExams)?
                            <div className='third-container'>
                                <div className='diagnostics-title'>Com nas hipóteses diagnósticas mais prováveis, é importante que você realize o(s) seguite(s) exame(s) abaixo:</div>
                                <div className='diagnostics'>
                                    {this.state.exams.map((exam, index) =>
                                        <div key={index} className='diagnosis-container'>
                                            <div className='diagnosis-text-container'>
                                                <div className='diagnosis-text'>{exam}</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='alert-searh-doctor'>
                                    As informações acima não substituem uma consulta médica.
                                </div>
                            </div>
                            : <div></div>
                        }
                        {(this.state.combo.price)?
                            <div className='combo-container'>
                                <div className='combo-text-inntroduction'>Aqui estão os produtos ideiais com os melhores preços</div>
                                <div className='combo-products'><strong>Promoção contendo:</strong></div>
                                <div className='explanation-container'>
                                    {this.state.combo.explanation.map((explanation, explanationIndex) => (
                                        <div id={"explanation-item-" + explanationIndex} key={explanationIndex} className='combo-products-explanation'>
                                        - <strong>{(explanation.split(" "))[0]}</strong> {explanation.substr(explanation.indexOf(" ") + 1)}
                                        </div>
                                    ))}
                                </div>
                                <div className="combo-image-container">
                                    <img className="combo-image" src={comboImage}/>
                                </div>
                                <div className='combo-price'>R$ {this.state.combo.price}</div>
                                {(this.state.comboInCart > 0)?
                                    <div className='product-quantity-container'>
                                        <span onClick={() => this.handleComboCart(false)} className="product-quantity-icon first-icon">-</span>
                                        <span className="product-quantity">{this.state.comboInCart}</span>
                                        <span onClick={() => this.handleComboCart(true)} className="product-quantity-icon last-icon">+</span>
                                    </div>
                                    :
                                    <Button onClick={() => this.handleComboCart(true)} className='identifier-button drug-button'>ADICIONAR</Button>
                                }
                                {(this.state.isClientCustomerFacing && this.state.clientModule != '')?
                                    <div>
                                        <a href={'https://www.extrafarma.com.br/checkout/?orderFormId=' + this.state.cartId + '#/cart'} target="_blank" className={(this.state.comboInCart > 0)? 'checkout-button-primary-care continue-payment' : 'display-none'} onClick={() => ReactGA.event({category: "IA em arvore",action: "IA - seguiu para pagamento",label: `IA - id do carrinho ${this.state.cartId}`, value: this.state.cartTicket})}>SEGUIR PARA PAGAMENTO</a>
                                    </div>
                                    : <div></div>
                                }
                            </div>
                            : <div></div>
                        }

                        {((this.state.productsList.length > 0 || this.state.secondaryProductsList.length > 0) && this.state.isLoading == false)?
                            <div className='fourth-container'>
                                <div>
                                    {(this.state.questionIndex > 1 && this.state.productsList.length > 0 && typeof this.state.diagnostics[0] !== 'undefined')?
                                        <div className='fourth-container-title'>Dado que o diagnóstico mais provável é <strong>{this.state.diagnostics[0]}</strong> segue abaixo os medicamentos ideiais para cada sintoma</div>
                                        : <div className='fourth-container-title'>Não tenho informações suficientes para gerar um diagnóstico. Então abaixo mais informações</div>
                                    }
                                    {(this.state.firstMedicineTextSelected != '')?
                                        <div className='cross-sell-title'>Além da {this.state.firstMedicineTextSelected}, o paciente pode se beneficiar de:</div>
                                        : <div></div>
                                    }
                                    <div>
                                        {this.state.productsList.map((symptom, symptomIndex) => (
                                            <div className={(symptomIndex%2 == 0)? "even-product-container striped-product-container" : "odd-product-container striped-product-container"} id={"symptom-item-" + symptomIndex} key={symptomIndex} >
                                                <div className="list-drug-title">Para tratamento do sintoma <u><strong>{symptom.symptom}</strong></u>:</div>
                                                <Table className="product-container">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th className="display-none">Tipo</th>
                                                            <th>Nome do produto</th>
                                                            <th>Preço</th>
                                                            <th>Lucro</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {symptom.products.map((product, productIndex) => (
                                                            <tr key={productIndex}>
                                                                <td>{productIndex}</td>
                                                                {(productIndex%3 == 0)? 
                                                                    <td className="product-type display-none"><i className="fa fa-medkit" aria-hidden="true"></i></td>
                                                                    :
                                                                    (productIndex%3 == 1)?
                                                                        <td className="product-type display-none"><i className="fa fa-leaf" aria-hidden="true"></i></td>
                                                                        :
                                                                        <td className="product-type display-none"><i className="fa fa-stethoscope" aria-hidden="true"></i></td>
                                                                }

                                                                <td>{(product.productTitle)? product.productTitle : product.title}</td>
                                                                <td>R${(product.items[0].sellers[0].commertialOffer.Price).toString().replace(".", ",")}</td>
                                                                {(productIndex == 0)?
                                                                    <td>R$9,9</td>
                                                                    :
                                                                    <td>R${(Math.round(product.items[0].sellers[0].commertialOffer.Price * 10) / 100).toString().replace(".", ",")}</td>
                                                                }
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                <div className="slider-container slider-container-web display-none">
                                                    <Swiper
                                                        onSlideChange={(e) => this.swipeProduct(e, true, symptomIndex)}
                                                        slidesPerView={1}
                                                        spaceBetween={30}
                                                        pagination={{
                                                            clickable: true,
                                                        }}
                                                        navigation={true}
                                                        modules={[Pagination, Navigation]}
                                                        className="mySwiper"
                                                        >
                                                        {symptom.products.map((product, productIndex) => (
                                                            <SwiperSlide key={productIndex}>
                                                                <div className="drug-image-container"><img className="drug-image" src={(product.items)? product.items[0].images[0].imageUrl : product.image}/></div>
                                                                <span className='drug-name'>{(product.productTitle)? product.productTitle : product.title}</span>
                                                                {(this.state.isClientCustomerFacing && this.state.clientModule != '')?
                                                                    <div className='drug-price'>R$ {(product.items[0].sellers[0].commertialOffer.Price).toString().replace(".", ",")}</div>
                                                                    : <div></div>
                                                                }
                                                                
                                                                {(this.state.isClientCustomerFacing && this.state.clientModule != '')?
                                                                    ((this.state.cartList[(productIndex.toString() + symptomIndex.toString())]) && this.state.cartList[(productIndex.toString() + symptomIndex.toString())].quantity > 0)?
                                                                        <div className='product-quantity-container'>
                                                                            <span onClick={() => this.handleCart((productIndex.toString() + symptomIndex.toString()),product.items[0].sellers[0].commertialOffer.Price , product.items[0].itemId, false)} className="product-quantity-icon first-icon">-</span>
                                                                            <span className="product-quantity">{this.state.cartList[(productIndex.toString() + symptomIndex.toString())].quantity}</span>
                                                                            <span onClick={() => this.handleCart((productIndex.toString() + symptomIndex.toString()),product.items[0].sellers[0].commertialOffer.Price , product.items[0].itemId, true)} className="product-quantity-icon last-icon">+</span>
                                                                        </div>
                                                                        :
                                                                        <Button onClick={() => this.handleCart((productIndex.toString() + symptomIndex.toString()),product.items[0].sellers[0].commertialOffer.Price , product.items[0].itemId, true)} className='identifier-button drug-button'>ADICIONAR</Button>
                                                                    : <div></div>
                                                                }

                                                            </SwiperSlide>
                                                        ))}
                                                    </Swiper>
                                                </div>
                                            </div>
                                        ))}
                                        {(this.state.secondaryProductsList.length > 0)?
                                            <div className="secondary-products-container">
                                                {(this.state.hasStartedWithAMedicine)?
                                                    <div className='up-sell-title'>Outros medicamentos similares ao <strong>{this.state.firstMedicineTextSelected}</strong>:</div>
                                                    :
                                                    <div className='list-drug-title'>Outros produtos para você:</div>
                                                }
                                                <Table className="product-container-secondary">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Nome do produto</th>
                                                            <th>Preço</th>
                                                            <th>Lucro</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.secondaryProductsList.map((symptom, symptomIndex) => (
                                                            symptom.products.map((product, productIndex) => (
                                                                <tr key={productIndex}>
                                                                    <td>{productIndex}</td>
                                                                    <td>{(product.productTitle)? product.productTitle : product.title}</td>
                                                                    <td>R${(product.items[0].sellers[0].commertialOffer.Price).toString().replace(".", ",")}</td>
                                                                    {(productIndex == 0)?
                                                                    <td>R$9,9</td>
                                                                    :
                                                                    <td>R${(Math.round(product.items[0].sellers[0].commertialOffer.Price * 10) / 100).toString().replace(".", ",")}</td>
                                                                }
                                                                </tr>
                                                            ))
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                
                                                <Swiper
                                                    onSlideChange={(e) => this.swipeProduct(e, false, 0)}
                                                    slidesPerView={1}
                                                    spaceBetween={30}
                                                    pagination={{
                                                        clickable: true,
                                                    }}
                                                    navigation={true}
                                                    modules={[Pagination, Navigation]}
                                                    className="mySwiper display-none"
                                                    >
                                                    {this.state.secondaryProductsList.map((symptom, symptomIndex) => (
                                                        symptom.products.map((product, productIndex) => (
                                                            <SwiperSlide key={productIndex}>
                                                                <div className="drug-image-container"><img className="drug-image" src={(product.items)? product.items[0].images[0].imageUrl : product.image}/></div>
                                                                <span className='drug-name'>{(product.productTitle)? product.productTitle : product.title}</span>
                                                                {(this.state.isClientCustomerFacing && this.state.clientModule != '')?
                                                                    <div className='drug-price'>R$ {(product.items[0].sellers[0].commertialOffer.Price).toString().replace(".", ",")}</div>
                                                                    : <div></div>
                                                                }
                                                                
                                                                {(this.state.isClientCustomerFacing && this.state.clientModule != '')?
                                                                    ((this.state.cartList[('x' + productIndex.toString())]) && this.state.cartList[('x' + productIndex.toString())].quantity > 0)?
                                                                        <div className='product-quantity-container'>
                                                                            <span onClick={() => this.handleCart(('x' + productIndex.toString()),product.items[0].sellers[0].commertialOffer.Price , product.items[0].itemId, false)} className="product-quantity-icon first-icon">-</span>
                                                                            <span className="product-quantity">{this.state.cartList[('x' + productIndex.toString())].quantity}</span>
                                                                            <span onClick={() => this.handleCart(('x' + productIndex.toString()),product.items[0].sellers[0].commertialOffer.Price , product.items[0].itemId, true)} className="product-quantity-icon last-icon">+</span>
                                                                        </div>
                                                                        :
                                                                        <Button onClick={() => this.handleCart(('x' + productIndex.toString()),product.items[0].sellers[0].commertialOffer.Price , product.items[0].itemId, true)} className='identifier-button drug-button'>ADICIONAR</Button>
                                                                    : <div></div>
                                                                }
                                                            </SwiperSlide>
                                                        ))
                                                    ))}
                                                </Swiper>
                                            </div>
                                            :<div></div>
                                        }
                                    </div>
                                </div>
                                {(this.state.isClientCustomerFacing && this.state.clientModule != '')?
                                    <div>
                                        <a href={'https://www.extrafarma.com.br/checkout/?orderFormId=' + this.state.cartId + '#/cart'} target="_blank" className={(Object.keys(this.state.cartList).length > 0)? 'checkout-button-primary-care continue-payment' : 'display-none'} onClick={() => ReactGA.event({category: "IA em arvore",action: "IA - seguiu para pagamento",label: `IA - id do carrinho ${this.state.cartId}`, value: this.state.cartTicket})}>SEGUIR PARA PAGAMENTO</a>
                                    </div>
                                    : <div></div>
                                }
                            </div>
                            : <div></div>
                        }
                        {(this.state.showSchedulePage)?
                            <div className='fifth-container'>
                                <div className='fifth-container-message'>
                                    Sentimos muito
                                    <br/>
                                    O agendamento de consultas não está disponível
                                </div>
                            </div>
                            : <div></div>
                        }
                    </section>
                }
            </Style>
        )
    }
}

export default primaryCare;
