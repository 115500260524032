import styled from 'styled-components';

export default styled.div`
    .splash-page {
        background: linear-gradient(to right bottom,rgb(0,91,236),rgb(38 105 211),rgb(75 128 213),rgb(81 124 193),rgb(36 104 213),rgb(0,97,255),rgb(0,97,255));
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .loading {
        position: absolute;
        top: calc(50% - 68px);
        left: calc(50% - 37px);
        height: 136px !important;
        width: 74px !important;
    }

    .powered-by {
        font-size: 13px;
        position: absolute;
        color: #c9c9c9;
        bottom: 60px;
        text-align: center;
        width: 100%;
    }

    .splash-text {
        color: rgb(255, 255, 255);
        text-align: center;
        font-size: 30px;
        margin-top: 50%;
    }

    .splash-first-text {
        margin-bottom: 25px;
        font-weight: bold;
    }

    .splash-second-text {
        font-size: 22px;
        line-height: 40px;
    }

    .continue-button {
        background-color: #fff;
        color: rgb(0, 119, 184);
        font-weight: bold;
        position: absolute;
        bottom: 25px;
        margin: 0;
        left: 20px;
        width: calc(100% - 40px);
    }

    .home-icon {
        color: rgb(255, 255, 255);
        font-size: 24px;
        text-align: center;
        position: absolute;
        bottom: 25px;
        width: 100%;
    }

    .display-none {
        display: none;
    }

    .first-page, .second-page, .third-page {
        padding: 15px;
    }

    .cpf {
        width: 100%;
        height: 38px;
        padding: 6px 12px;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        color: #495057;
    }

    .cpf:focus {
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem #007bff38;
    }

    .submit-form {
        position: absolute;
        bottom: 25px;
        width: calc(100% - 30px);
        left: 15px;
    }

    .installments {
        width: 100%;
        height: 38px;
        padding: 6px 12px;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        color: #495057;
        background-color: #fff;
    }

    select.installments:focus-visible {
        outline: none;
    }

    .return-message {
        padding: 15px;
        color: rgb(0, 119, 184);
        font-size: 22px;
        text-align: center;
        font-weight: bold;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .fifty-input-container {
        display: flex;
    }

    .fifty-input {
        width: 50%;
    }

    .fifty-input:nth-child(2) {
        margin-left: 5px;
    }
    .fifty-input:first-child {
        margin-right: 5px;
    }
`;