import React, { Component } from 'react';
import Style from './Style';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { FaRegUser } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import BALTO from '../../BALTO.png';
import { postOnDataLake } from "../../helpers/postOnDataLake.js";
const ref = React.createRef();

class WebHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenuNav: false
        };
        this.toggleMenuNav = this.toggleMenuNav.bind(this);
    }

    toggleMenuNav() {
        this.setState({
            showMenuNav: !this.state.showMenuNav
        })
    }

    render() {
        return (
            <Style className="col-12">
                <div className={"main-container " + (this.state.showMenuNav ? 'show-menu-main-container' : '')}>
                    <img className="balto-icon" src={BALTO} />
                    <div className="right-container">
                        <div className="simple-link-container">
                            <AnchorLink offset='78' href='#contact' className="anchor-link" onClick={() => this.thisPostOnDataLake("Web - Clicou em Falar com especialista")}>
                                <div className="simple-link contact-button">Teste grátis</div>
                            </AnchorLink>
                        </div>
                    </div>
                </div>
                <div className={"contact-message " + (this.props.showContactMessage === 1 ? 'success' : (this.props.showContactMessage === -1)? 'error' : '')}>
                    {
                        (this.props.showContactMessage === -1)?
                        <span>Não conseguimos enviar o seu contato, recarregue a página e tente novamente</span> :
                        (this.props.showContactMessage === 1)?
                        <span>Recebemos seu contato, em breve te retornaremos!</span> :
                        ''
                    }
                </div>
            </Style>
        );
    }
}

export default WebHeader;