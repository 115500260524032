import styled from 'styled-components';

export default styled.div`
    background-color: #005bec;
    color: #fff;
    padding-top: 50px;

    .main-container {
        margin: auto;
        max-width: 1000px;
        padding: 50px 0px 25px;
    }

    .first-row {
        text-align: center;
    }

    .main-container .first-row h3 {
        margin: auto;
        max-width: 350px;
        text-align: center;
        margin-bottom: 75px;
    }

    .first-row svg {
        font-size: 35px;
        margin: 35px 15px 100px;
        color: #fff;
    }

    .second-row {
        border-top: 1px solid;
        padding-top: 25px;
    }

    .simple-link div {
        padding: 5px 0px;
    }

    .second-inside-row {
        display: flex;
    }

    .anchor-link, .simple-link {
        color: #fff;
    }

    .balto-icon {
        width: 100px;
        margin: auto;
        display: block;
    }

    .second-row .home-icon, .second-row .simple-link {
        margin: auto;
        font-size: 17px;
        padding: 0px;
        width: 33%;
        text-align: center;
    }

    .second-row .simple-link-container svg {
        font-size: 30px;
        margin: 0px 12px 0px;
        color: #fff;
    }

    .second-row .home-icon:hover, .second-row .simple-link:hover, .main-container svg:hover {
        color: #fff;
        cursor: pointer;
    }

    .right-container {
        margin-left: auto;
    }

    .third-row {
        padding-top: 50px;
    }

    .third-row div {
        text-align: center;
    }

    .third-row div.home-icon {
        color: #fff;
        font-size: 23px;
    }

    .third-row div.home-address {
        color: #dfdfdf;
        font-size: 15px;
        padding-top: 10px;
    }

    @media only screen and (max-width: 675px) {
        .second-inside-row {
            display: block;
        }

        .second-row .home-icon, .second-row .simple-link {
            margin-right: 0px;
            text-align: center;
        }

        .right-container {
            justify-content: center;
            margin-top: 30px;
        }
    }
`;