import styled from 'styled-components';

export default styled.div`
    .home-first-container, .home-second-container, .home-third-container, .home-insurance-container, .home-fourth-container, .home-fifth-container, .home-sixth-container, .home-first-second-container {
        padding: 70px 15px;
    }

    .home-first-container {
        background-image: linear-gradient(31.53deg,#f4f8ff 3.69%,#dbe9ff 78.33%,#3f88ff 165.68%);
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: center;
    }

    .home-first-container-main, .home-first-second-sub-container, .home-second-container, .home-third-container-main, .home-insurance-container-main, .home-sixth-container-main {
        display: flex;
        max-width: 1200px;
        margin: auto;
    }

    .home-first-left-container {
        width: 45%;
    }

    .home-first-right-container {
        width: 65%;
    }

    .home-first-left-container h1 {
        font-size: 48px;
        font-weight: bold;
        color: black;
    }

    .home-first-left-container p {
        font-size: 22px;
    }

    .home-first-container-contact-button {
        color: #000;
        margin: 30px 0px;
        background-color: #fff;
        padding: 12px 20px;
        text-align: left;
        width: 100%;
        font-size: 18px;
        border: 1px solid #e5e2e2;
        position: relative;
    }

    .home-first-second-container-contact-button {
        color: #000;
        margin: 30px 0px;
        background-color: #fff;
        padding: 12px 20px;
        text-align: left;
        font-weight: bold;
        font-size: 18px;
        border: 1px solid #e5e2e2;
        position: relative;
    }

    .home-first-second-container-contact-button i {
        margin-left: 10px;
    }

    .home-first-container-contact-button img {
        position: absolute;
        right: 35px;
        top: 14.home-5px;
    }

    .home-dashboard-first-container {
        margin: auto;
        display: block;
        height: auto;
        width: 100%;
        max-height: 600px;
        padding-left: 60px;
    }

    .home-first-second-container {
        color: #fff;
        background: rgb(1, 31, 79);
        background: linear-gradient(90deg, rgba(1, 31, 79, 1) 0%, rgba(0, 91, 236, 1) 77%, rgba(0, 91, 236, 0.7609418767507002) 100%);
    }

    .home-second-container-left {
        width: 50%;
    }

    .home-first-second-container-left {
        width: 50%;
    }

    .home-first-second-container-left img {
        display: block;
        margin: 0px 0px auto auto;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        top: 50%;
        position: relative;
        width: 100%;
        max-width: 450px;
    }

    .home-second-container-left img {
        max-height: 350px;
        width: auto;
    }

    .home-second-container-right {
        padding-left: 53px;
        margin: auto;
    }

    .home-third-container {
        padding-bottom: 270px;
    }

    .home-second-container-right p, .home-third-container-left p, .home-first-second-container-right p {
        font-size: 20px;
    }

    .home-second-container h2, .home-third-container h2, .home-first-second-container-right h2, .home-insurance-container h2, .home-fourth-container-main h2, .home-fifth-container h2, .home-sixth-container h2 {
        font-weight: bold;
        margin-bottom: 50px;
    }

    .home-first-second-container-right {
        width: 50%;
    }

    .home-block-group {
        position: relative;
        padding: 0px 15px;
    }

    .home-block-group-container-main {
        max-width: 1200px;
        background-color: #fff;
        border-radius: 15px;
        padding: 20px;
        box-shadow: 0 0 20px 0 #efefef;
        margin: auto;
        padding-bottom: 40px;
        position: absolute;
        width: calc(100% - 30px);
        top: -152px;
        left: 0;
        right: 0;
    }

    .home-block-group h2 {
        text-align: center;
        font-size: 18px;
        color: rgb(84,95,104);
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 60px;
        margin-top: 25px;
    }

    .home-block-group-container {
        display: flex;
    }

    .home-block-group-container span {
        width: 25%;
        text-align:center;
    }

    .home-text-number-under-h2 {
        font-size: 50px;
        color: #005bec;
        font-weight: bold;
    }

    .home-text-under-h2 {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .home-bullet-value-prop {
        padding-left: 20px;
    }

    .home-bullet-value-prop li {
        font-size: 20px;
        padding-bottom: 20px;
    }

    .home-mobile {
        display: none;
    }

    .balto-video-player {
        margin-left: -160px;
    }

    .home-mobile .balto-video-player {
        margin-left: 0px;
        width: 100%;
    }

    .home-third-container, .home-sixth-container {
        background-color: #E4EEFE;
    }

    .home-third-container-left {
        width: 50%;
        padding-right: 53px;
    }

    .home-third-container-right {
        width: 50%;
        align-items: center;
        display: flex;
    }

    .home-third-container-right img {
        width: 100%;
        height: auto;
    }

    .home-fourth-container {
        padding-top: 200px;
        background: linear-gradient(to bottom right, #005bec, #1a72ff, #5798ff, #75aaff, #2a7bfd, #0061ff, #0061ff);
        color: #fff;
    }

    .home-fourth-container-main {
        max-width: 1200px;
        display: block;
        margin: auto;
    }

    .home-fourth-container-main h3 {
        font-size: 24px;
        margin-bottom: 25px;
    }

    .home-swiper .swiper-slide {
        padding: 30px 50px 50px;
        min-height: 242px;
        background-color: transparent;
        border: 0px !important;
        box-shadow: none;
    }

    .swipe-text-person {
        font-size: 20px;
    }

    .home-swiper .swiper-slide .swipe-text-message {
        font-size: 26px;
        padding-bottom: 25px;
    }

    .swipe-text-message, .swipe-text-person {
        color: #fff;
    }

    .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after, .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
        color: #fff;
    }

    .home-how-it-works-container {
        display: flex;
        overflow-x: auto;
    }

    .home-step-container {
        width: 25%;
        padding: 0px 30px;
        min-width: 240px;
    }

    .home-img-container {
        height: 245px;
        position: relative;
    }

    .home-step-container img {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        max-height: 200px;
        width: auto;
        max-width: 222px;
    }

    .home-step-container:first-child {
        padding-left: 0px;
    }

    .home-step-container:last-child {
        padding-right: 0px;
    }

    .home-step-number {
        border: 2px solid white;
        border-radius: 25px;
        text-align: center;
        width: 50px;
        margin: 30px auto;
        font-size: 22px;
        padding: 6px;
        font-weight: bold;
    }

    .home-how-it-works-container::-webkit-scrollbar {
        width: 0px;
    }

    .home-step-text {
        font-size: 18px;
        text-align: center;
        display: table;
    }

    .home-fifth-container-main {
        max-width: 1200px;
        margin: auto;
    }

    .card-midia {
        display: inline-flex;
        width: 25%;
        width: 25%;
        min-width: 200px;
    }

    .card-midia a {
        display: block;
        width: 150px;
        height: 150px;
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
        background-repeat: no-repeat, repeat;
        background-size: contain;
        background-position: center;
        margin: auto;
    }

    .home-fifth-container, home-sixth-container {
        background: linear-gradient(31.home-53deg,#F4F8FF 40.home-69%,#DBE9FF 78.home-33%,#3F88FF 165.home-68%);
    }

    .home-sixth-container {
        padding-bottom: 100px;
    }

    .home-fifth-container-left, home-sixth-container-left {
        width: 33%;
    }

    .home-fifth-container-right, home-sixth-container-right {
        width: 67%;
    }

    .home-fifth-container-right, home-sixth-container-right {
        padding-left: 100px;
    }

    .home-inline-input {
        width: 50%;
        display: inline-block;
    }

    .home-fifth-container-right label, home-sixth-container-right label {
        font-size: 17px;
    }

    .home-inline-input-right {
        padding-left: 15px;
    }

    .home-third-container-left .home-submit-form {
        margin-top: 30px;
    }

    .home-inline-input-left {
        padding-right: 15px;
    }

    .home-submit-form {
        background-color: #005bec;
        padding: 12px 52px;
        font-size: 18px;
    }

    .home-submit-form i {
        padding-left: 10px;
    }

    .home-sixth-container-right input, .home-sixth-container-right textarea {
        border-color: #888;
    }

    @media only screen and (min-width: 1000px) {
        .home-first-container-contact-button {
            width: 176px;
            text-align: center;
        }

        .home-first-container-contact-button img {
            position: unset;
            padding-left: 10px;
            padding-bottom: 3px;
        }
    }

    @media only screen and (max-width: 700px) {
        .home-third-container {
            padding-bottom: 400px;
        }

        .home-block-group-container {
            display: block;
        }

        .home-block-group-container-main {
            top: -300px
        }

        .home-block-group h2 {
            font-size: 16px;
        }

        .home-text-number-under-h2 {
            font-size: 35px;
        }

        .home-text-under-h2 {
            font-size: 16px;
        }

        .home-fourth-container {
            padding-top: 380px;
        }
    }

    @media only screen and (max-width: 1000px) {
        .home-first-second-sub-container {
            display: block;
        }

        .home-first-second-container-left {
            width: 100%;
        }

        .home-first-second-container-left img {
            margin: auto;
            transform: none;
        }

        .home-first-second-container-right {
            width: 100%;
        }

        .home-first-container, .home-first-second-container {
            padding-top: 30px;
        }

        .home-first-container-main {
            display: block;
        }

        .home-first-left-container, .home-first-right-container {
            width: 100%;
        }

        .home-dashboard-first-container {
            padding: 0px;
        }

        .home-first-left-container {
            padding-bottom: 50px;
        }

        .home-first-left-container h1 {
            font-size: 38px;
        }

        .home-first-left-container p {
            font-size: 18px;
        }

        .home-bullet-value-prop li {
            font-size: 18px;
            padding-bottom: 10px;
        }

        .home-second-container, .home-third-container-main, .home-fifth-container-main, .home-sixth-container-main {
            display: unset;
        }

        .home-second-container-right {
            padding: 0px 15px;
        }

        .home-second-container-left, .home-third-container-left, .home-third-container-right, .home-fifth-container-left, .home-fifth-container-right, .home-sixth-container-left, .home-sixth-container-right {
            width: 100%
        }

        .home-third-container-left {
            padding-right: 0px;
        }

        .home-second-container-left img {
            margin: auto;
            display: block;
        }

        .home-isurance-container img {
            max-height: 289px;
        }

        .home-web {
            display: none;
        }

        .home-mobile {
            display: block;
        }

        .home-fifth-container-left, .home-sixth-container-left {
            text-align: center;
            padding-bottom: 25px;
        }

        .home-fifth-container-right, .home-sixth-container-right {
            padding-left: 0px;
        }

        .home-inline-input {
            width: 100%;
        }

        .home-inline-input-right {
            padding-left: 0px;
        }

        .home-inline-input-left {
            padding-right: 0px;
        }

        .home-submit-form {
            width: 100%;
        }
    }

    @media only screen and (max-width: 400px) {
        .home-first-left-container h1 {
            font-size: 33px;
        }

        .home-second-container-left img {
            max-height: 300px;
        }

        .home-isurance-container img {
            max-height: 228px;
        }

        .card-midia {
            display: block;
            margin: auto;
        }
    }
`;