import React, { Component } from 'react';
import Style from './Style';
import ReactLoading from 'react-loading';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import ReactGA from "react-ga4";
import { addbnpl } from "../../helpers/bnpl.js";
import { Helmet } from "react-helmet";

class bnpl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            pageNumber: 0,
            name: '',
            cpf: '',
            birthday: '',
            products: '',
            totalPrice: 0,
            creditNumber: '',
            expirationDate: '',
            cvc: ''
        };
        this.removeLoading = this.removeLoading.bind(this);
        this.postEvent = this.postEvent.bind(this);
        this.inputChatChange = this.inputChatChange.bind(this);
        this.transformInstallment = this.transformInstallment.bind(this);
        
        setTimeout(function() {
            this.removeLoading();
        }.bind(this), 1700);

        ReactGA.event({
            category: "bnpl",
            action: "bnpl - initialized",
            label: "bnpl - initialized"
        });
    }

    removeLoading() {
        this.setState({isLoading: false})
    }

    postEvent(e, formIndex) {
        e.preventDefault();
        if (formIndex == 0) {
            this.setState({ pageNumber: 1 });
            
            ReactGA.event({
                category: "bnpl",
                action: "bnpl - clicked started",
                label: "bnpl - clicked started"
            });
        }
        else if (formIndex == 1) {
            this.setState({
                name: e.target[0].value,
                cpf: e.target[1].value,
                birthday: e.target[2].value,
                pageNumber: 2
            });

            ReactGA.event({
                category: "bnpl",
                action: "bnpl - entered personal data",
                label: "bnpl - entered personal data"
            });
        }
        else if (formIndex == 2) {
            this.setState({
                pageNumber: 3,
                products: e.target[0].value,
                totalPrice: e.target[1].value
            });

            ReactGA.event({
                category: "bnpl",
                action: "bnpl - entered products and price",
                label: "bnpl - entered products and price"
            });

            let body = {
                "name": this.state.name,
                "cpf": this.state.cpf,
                "age": this.state.birthday,
                "productName": e.target[0].value,
                "price": e.target[1].value
            };

            addbnpl(body);
        }
        else if (formIndex == 3) {
            this.setState({
                pageNumber: 4
            });

            ReactGA.event({
                category: "bnpl",
                action: "bnpl - confirmed installments",
                label: "bnpl - confirmed installments"
            });
        }
        else if (formIndex == 4) {
            if (e.target[0].value == 'Não') {
                this.setState({
                    pageNumber: 6
                }, () => {
                    setTimeout(function() {
                        this.removeLoading();
                    }.bind(this), 1700)
                })

                ReactGA.event({
                    category: "bnpl",
                    action: `bnpl - confirmed credit card with Não`,
                    label: `bnpl - confirmed credit card with Não`
                });
            }
            else {
                this.setState({
                    pageNumber: 5
                })

                ReactGA.event({
                    category: "bnpl",
                    action: `bnpl - confirmed credit card with Sim`,
                    label: `bnpl - confirmed credit card with Sim`
                });
            }
        }
        else if (formIndex == 5) {
            this.setState({
                pageNumber: 6,
                isLoading: true
            }, () => {
                setTimeout(function() {
                    this.removeLoading();
                }.bind(this), 1700)
            });

            ReactGA.event({
                category: "bnpl",
                action: `bnpl - sent credit card form`,
                label: `bnpl - sent credit card form`
            });
        }
    }

    inputChatChange(e, key) {
        this.setState({ [key]: e.target.value });
    }

    transformInstallment(installments) {
        let decimal = '';
        let value = ((Math.floor((this.state.totalPrice/installments) * 1.05 * 100) / 100).toString()).replace('.', ',');
        
        if (value.indexOf(',') < 0)
            decimal = ',00';
        else if ((value.length - value.indexOf(',')) == 2)
            decimal = '0';

        return (value + decimal);
    }

    render() {
        return (
            <Style>
                <Helmet>
                    <meta name="robots" content="noindex"/>
                </Helmet>
                <div className={(this.state.pageNumber == 0)? "splash-page" : "display-none"}>
                    <ReactLoading className={(this.state.isLoading)? "loading" : "display-none"} type={"spinningBubbles"} height={'20%'} width={'20%'} />
                    <span className={(this.state.isLoading)? "powered-by" : "display-none"}>Powered by</span>
                    <div className={(this.state.isLoading)? "home-icon" : "display-none"}>Balto</div>
                    <div className={(!this.state.isLoading)? "splash-container" : "display-none"}>
                        <div className="splash-text">
                            <div className="splash-first-text">
                                Sua saúde não precisa <br/> esperar o mês que vem
                            </div>
                            <div className="splash-second-text">
                                Parcelamento <br/> Sem cartão <br/> Sem burocracia
                            </div>
                            <Button onClick={(e) => this.postEvent(e, 0)} className="continue-button" type="submit">
                                COMEÇAR
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={(this.state.pageNumber == 1)? "first-page" : "display-none"}>
                    <Form onSubmit={(e) => this.postEvent(e, 1)}>
                        <Form.Group controlId="userName" className="home-inline-input">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control type="text"
                                placeholder="Seu nome"
                                autoFocus
                                name="userName"
                                required />
                        </Form.Group>

                        <Form.Group controlId="cpf" className="cpf-input">
                            <Form.Label>CPF</Form.Label> <br/>
                            <InputMask
                                className="cpf"
                                onChange={(e) => this.inputChatChange(e, 'cpf')}
                                placeholder="999.999.999-99"
                                value={this.state.cpf}
                                mask='999.999.999-99'>
                            </InputMask>
                        </Form.Group>

                        <Form.Group controlId="birthday" className="home-inline-input">
                            <Form.Label>Data de nascimento</Form.Label>
                            <Form.Control type="date"
                                name="birthday"
                                required />
                        </Form.Group>
                        <Button className="submit-form" type="submit">CONTINUAR</Button>
                    </Form>
                </div>
                <div className={(this.state.pageNumber == 2)? "second-page" : "display-none"}>
                    <Form onSubmit={(e) => this.postEvent(e, 2)}>
                        <Form.Group controlId="products" className="home-inline-input">
                            <Form.Label>Descreva os produtos separados por vírgula</Form.Label>
                            <Form.Control type="text"
                                placeholder="Dipirona, Amoxicilina, Nimesulida"
                                name="products"
                                required />
                        </Form.Group>

                        <Form.Group controlId="totalPrice" className="home-inline-input">
                            <Form.Label>Insira o valor total da compra</Form.Label>
                            <Form.Control type="number"
                                name="totalPrice"
                                placeholder="R$999,99"
                                required />
                        </Form.Group>
                        <Button className="submit-form" type="submit">REVISAR PARCELAS</Button>
                    </Form>
                </div>

                <div className={(this.state.pageNumber == 3)? "third-page" : "display-none"}>
                    <Form onSubmit={(e) => this.postEvent(e, 3)}>
                        <Form.Label>Em quantas vezes deseja parcelar?</Form.Label>
                        <select className="installments">
                            <option value="2">2x R$ {this.transformInstallment(2)}</option>
                            <option value="3">3x R$ {this.transformInstallment(3)}</option>
                            <option value="4">4x R$ {this.transformInstallment(4)}</option>
                            <option value="5">5x R$ {this.transformInstallment(5)}</option>
                        </select>
                        <Button className="submit-form" type="submit">INSERIR DADOS FINANCEIROS</Button>
                    </Form>
                </div>

                <div className={(this.state.pageNumber == 4)? "third-page" : "display-none"}>
                    <Form onSubmit={(e) => this.postEvent(e, 4)}>
                        <Form.Label>Você possui cartão de crédito?</Form.Label>
                        <select className="installments">
                            <option value="Sim">Sim</option>
                            <option value="Não">Não</option>
                        </select>
                        <Button className="submit-form" type="submit">REVISAR DADOS FINANCEIROS</Button>
                    </Form>
                </div>

                <div className={(this.state.pageNumber == 5)? "third-page" : "display-none"}>
                    <Form onSubmit={(e) => this.postEvent(e, 5)}>
                        <Form.Group controlId="creditNumber" className="home-inline-input">
                            <Form.Label>Número do cartão</Form.Label>
                            <br/>
                            <InputMask
                                className="cpf"
                                required
                                onChange={(e) => this.inputChatChange(e, 'creditNumber')}
                                placeholder="1234 1234 1234 1234"
                                value={this.state.creditNumber}
                                mask='9999 9999 9999 9999'>
                            </InputMask>
                        </Form.Group>

                        <div className="fifty-input-container">
                            <Form.Group controlId="expirationDate" className="fifty-input">
                                <Form.Label>Data de expiração</Form.Label> <br/>
                                <InputMask
                                    className="cpf"
                                    required
                                    onChange={(e) => this.inputChatChange(e, 'expirationDate')}
                                    placeholder="DD/MM"
                                    value={this.state.expirationDate}
                                    mask='99/99'>
                                </InputMask>
                            </Form.Group>

                            <Form.Group controlId="cvc" className="fifty-input">
                                <Form.Label>CVC/CVV</Form.Label> <br/>
                                <InputMask
                                    className="cpf"
                                    required
                                    onChange={(e) => this.inputChatChange(e, 'cvc')}
                                    placeholder="123"
                                    value={this.state.cvc}
                                    mask='999'>
                                </InputMask>
                            </Form.Group>
                        </div>

                        <Form.Group controlId="cardName" className="home-inline-input">
                            <Form.Label>Nome no cartão</Form.Label>
                            <Form.Control type="text"
                                name="cardName"
                                placeholder="Amanda A J Lima"
                                required />
                        </Form.Group>
                        <Button className="submit-form" type="submit">FINALIZAR</Button>
                    </Form>
                </div>

                <div className={(this.state.pageNumber == 6)? "fourth-page" : "display-none"}>
                    <ReactLoading className={(this.state.isLoading)? "loading" : "display-none"} type={"spinningBubbles"} color={"blue"} height={'20%'} width={'20%'} />
                    <div className={(!this.state.isLoading)? "return-message" : "display-none"}>
                        Sentimos muito
                        <br/>
                        <br/>
                        Não conseguimos aprovar o seu parcelamento
                    </div>
                </div>
            </Style>
        )
    }
}

export default bnpl;