import axios from 'axios';
import config from  '../config/config.json';
const clientId = window.localStorage.getItem('client-id');
const token = window.localStorage.getItem('access-token');

export const addFeedback = (chatId, wasFeedbackPositive, observation) => {
    const env = process.env.REACT_APP_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    let headers = {
        'client-id': clientId,
        'access-token': token
    };

    let obj = {
        chatId: chatId,
        wasFeedbackPositive: wasFeedbackPositive,
        observation: observation
    };

    return axios.post(`${apiUrl}/physician-feedback`, obj, {headers});
}