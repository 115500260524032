import styled from 'styled-components';

export default styled.div`
    .privacy-container {
        padding: 75px 15px;
        margin: auto;
        max-width: 1200px;
        text-align: justify;
    }

    .privacy-container h1 {
        text-align: center;
    }

    .privacy-container h2, .privacy-container ul {
        padding-bottom: 10px;
    }

    .privacy-container p, .privacy-container ul, .privacy-container ol, .dpo-contact {
        color: #555;
    }

    ul.do-not-show-bullet {
        list-style: lower-latin;
    }

    .privacy-container li p {
        font-size: 13px;
    }

    .dpo-contact {
        text-decoration: underline;
    }
`;