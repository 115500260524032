import styled from 'styled-components';

export default styled.div`
    .main-container {
        padding: 0px 10px 40px 10px;
        margin: auto;
        max-width: 1000px;
        text-align: justify;
    }

    h1 {
        padding: 30px 0px;
        text-align: center;
        color: #262626;
        font-size: 24px;
    }

    h2 {
        color: #262626;
        font-size: 20px;
    }

    .bold-text {
        font-weight: bold;
    }
`;