import React, { Component } from 'react';
import Style from './Style';
const clientId = window.localStorage.getItem('jet7-client-id');

class Privacy extends Component{
    render() {
        return (
            <Style>
                <div className="privacy-container">
                    <h1>Política de Privacidade</h1>
                    <br/>
                    <p>A Balto tem o compromisso com a transparência, a privacidade e a segurança dos dados de seus clientes. Por esse motivo, criou esta política de privacidade (“Política”) para explicar como coletamos e tratamos informações dos nossos clientes pessoas físicas e/ou dos representantes dos clientes Balto (“Clientes Balto”), (conjuntamente com Clientes e Usuários Finais, “Você”) que, juntas ou isoladamente possam identificar Você (“Dados Pessoais”).</p>
                    <p>A Política de Privacidade, detalhada a seguir, demonstra como os seus dados pessoais são tratados, quais informações coletamos e como as utilizamos, armazenamos ou excluímos, em linha com as disposições da Lei Geral de Proteção de Dados (Lei 13.709/18).</p>
                    <p>Ressaltamos que não é possível oferecer as funcionalidades da plataforma e os nossos serviços sem ter acesso aos Dados Pessoais. Não coletamos mais dados do que os estritamente necessários para a execução dessas atividades. Caso Você não esteja de acordo com as disposições desta Política, Você deverá descontinuar o uso dos nossos serviços e/ou o seu acesso ou uso de nosso site.</p>

                    <br/>

                    <h2>1. Definições importantes</h2>
                    <p>Para que a Política de Privacidade seja bem compreendida, é fundamental esclarecer alguns conceitos importantes:</p>
                    <ul className="do-not-show-bullet">
                        <li><strong>Balto</strong> – é a marca que atua como: (a) controladora dos Dados Pessoais de Clientes; e (b) operadora dos Dados Pessoais de Usuários Finais, para o cumprimento das finalidades aqui estabelecidas.</li>
                        <li><strong>Dados pessoais</strong> – qualquer informação que possa identificá-lo(a) de maneira direta ou indireta, tais como dados cadastrais, preferências, dados sobre utilização de serviços vinculados à sua pessoa e identificadores eletrônicos</li>
                        <li><strong>Tratamento de dados pessoais</strong> – considera-se tratamento de dado pessoal a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, comunicação, transferência, difusão ou extração de dados de pessoas físicas.</li>
                        <li><strong>Titular de dados</strong> – qualquer pessoa física que tenha seus dados pessoais tratados pela Balto.</li>
                        <li><strong>Finalidade</strong> – o que queremos alcançar com o tratamento de dados pessoais.</li>
                        <li><strong>Necessidade</strong> – o tratamento de dados pessoais deve se limitar ao mínimo necessário para o propósito almejado. Ou seja, deve ser pertinente, proporcional e não excessivo.</li>
                        <li><strong>Consentimento</strong> – autorização livre, informada e inequívoca que o titular dá para tratamento de seus dados pessoais com finalidade previamente estipulada. Após dar o consentimento, você pode revogá-lo a qualquer momento. A revogação não cancela o tratamento realizado previamente.</li>
                    </ul>

                    <br/>

                    <h2>2. Como e para que utilizamos dados pessoais?</h2>
                    <p>Listamos abaixo o modo e finalidades pelos quais coletamos seus dados pessoais:</p>
                    <ul>
                        <li><strong>Cuidados Preventivos</strong>: podemos coletar e tratar dados necessários para que os Clientes Balto possam prover os serviços de cuidados preventivos à saúde do paciente. Neste caso, dependendo da frente de atuação escolhida para os cuidados preventivos, para viabilizar seu atendimento e acompanhamento da sua saúde coletaremos seu nome, CPF, endereço, dados de geolocalização, data de nascimento, estado civil, escolaridade, religião, etnia, nacionalidade, nome do pai, mãe, cônjuge, e-mail, telefone, cartão de saúde, dados biométricos, dados sobre o estado de saúde, alergias, imagens de ferimentos, resultados de exames, medicação em uso, histórico familiar de doenças.</li>
                        <br/>
                        <li>
                            <strong>Análise de mau uso e fraude</strong>
                            : podemos coletar e tratar dados necessários para que os Clientes Balto possam prover planos de saúde personalizados e para diminuição do mau uso e fraude, que por consequência acesso a planos de saúde mais justos.
                            <br/>
                            <br/>
                            Para viabilizar esta solução entregaremos os dados de forma anonimizada, e coletaremos seu nome, CPF, endereço, dados de geolocalização, data de nascimento, estado civil, escolaridade, religião, etnia, nacionalidade, nome do pai, mãe, cônjuge, e-mail, telefone, cartão de saúde, dados biométricos, dados sobre o estado de saúde, alergias, imagens de ferimentos, resultados de exames, medicação em uso, histórico familiar de doenças.
                        </li>
                        <br/>
                        <li><strong>Desenvolver novos produtos e serviços</strong>: utilizaremos seus dados pessoais para colaborar com o desenvolvimento de novos produtos, serviços, eventos e oportunidades, inclusive por meio de técnicas de analytics (o uso aplicado de dados, análises e raciocínio sistemático para seguir em um processo de tomada de decisão eficiente). Quando isso for feito, tomaremos o cuidado para que os dados utilizados não identifiquem os titulares ou seus dependentes (de forma estatística e anonimizada). </li>
                        <br/>
                        <li>
                            <strong>Realizar pesquisas clínicas e Programas de Suporte ao Paciente</strong>
                            : utilizaremos seus dados pessoais para auxílio no estudo de pesquisas clínicas ou Programas de Suporte ao Paciente, nós e/ou nossos parceiros coletaremos dados pessoais, a fim de descobrir ou verificar os efeitos farmacodinâmicos, farmacológicos, clínicos e/ou outros efeitos de produto(s) em investigação com o objetivo de averiguar sua segurança e/ou eficácia, de acordo com o informado na ocasião e respeitando as legislações aplicáveis, sempre respeitando as finalidades de pesquisa às quais houver o seu consentimento, o qual poderá ser revogado.
                            <br/>
                            <br/>
                            Para viabilizar esta solução, coletaremos os seguintes dados: iniciais de seu nome, seu número de participação no estudo, data de nascimento e alguns dados de saúde relacionados ao teor da pesquisa. Sempre que isso for feito, tomaremos o cuidado para que os dados utilizados sejam, sempre que possível, anonimizados. Ainda, quando for preciso, as pesquisas serão acompanhadas pelo Comitê de Ética e Pesquisa, com todas as cautelas necessárias, garantida a segurança das suas informações durante todo o processo. 
                        </li>
                        <br/>
                        <li><strong>Realizar pesquisas de satisfação</strong>: A sua opinião é muito importante para nós. Por isso, costumamos realizar pesquisas de satisfação após a utilização dos nossos serviços, para que possamos entender a jornada dos nossos pacientes. Sempre que você aceitar participar, tomaremos o cuidado para que os dados sejam, sempre que possível, utilizados de forma estatística.</li>
                        <br/>
                        <li><strong>Auditorias Internas</strong>: Para que a gente esteja sempre em rota de melhoria dos nossos serviços, precisamos realizar auditorias o que, eventualmente, poderá envolver informações, inclusive clínicas, por exemplo, quando estamos realizando auditoria de prontuário médico, caso em que serão observados todos os protocolos de segurança, normas de acreditação e regulatórias, sendo realizadas por profissionais comprometidos com o dever de sigilo profissional</li>
                    </ul>

                    <br/>

                    <h2>3. Privacidade de Crianças</h2>
                    <p>Alguns dos nossos serviços poderão ser prestados a crianças, ou seja, pessoas menores de 12 anos, bem como podemos receber seus dados por meio de nossos clientes ou operadoras de saúde. Nesses casos, sempre que for o caso, buscaremos a autorização de seus representantes legais, nos termos das legislações aplicáveis.</p>

                    <br/>

                    <h2>4. Quais dados são coletados?</h2>
                    <ul>
                        <li><strong>Dados Cadastrais</strong>: como nome completo, RG, CPF, data e local de nascimento, idade, nome do responsável (se menor de idade), foto, endereço, estado civil, escolaridade, religião, etnia, nacionalidade, nome do pai, nome da mãe, nome do cônjuge, e-mail, telefone, cartão de saúde, número de contato, gênero, endereço completo, número de protocolo e senha para acesso às plataformas.</li>
                        <br/>
                        <li><strong>Dados de Saúde</strong>: peso, altura, data e hora da última refeição, data da última menstruação, medicamentos de uso contínuo, informações sobre seu plano de saúde (se houver), informações associadas ao pedido médico do exame, imagens de exames e tomografias, diagnóstico médico, dados sobre o estado de saúde, alergias, imagens de ferimentos, medicação em uso, atestados médicos, fichas clínicas, prontuários médicos, agendamentos de exames, histórico familiar de doenças e demais dados pessoais que possam estar contidos nos exames ou tratamentos realizados, bem como aqueles exigidos por uma legislação aplicável.</li>
                        <br/>
                        <li><strong>Dados trabalhistas</strong>: CTPS, PIS/PASEP, ocupação, cargo, endereço comercial, Atestado de Saúde Ocupacional;</li>
                        <br/>
                        <li><strong>Profissionais de Saúde</strong>: que podem envolver nome completo, e-mail, foto, data de nascimento, CPF, RG, gênero, telefones de contato, currículo, dados de pagamento, endereço, certidão de ética, dados do registro nos órgãos de classe e outras informações necessárias à prestação dos nossos serviços.</li>
                        <br/>
                        <li><strong>Dados de Localização</strong>: podemos coletar dados de localização geográfica, como GPS, pontos de acesso Wi-Fi e torres de celular nas proximidades.</li>
                        <br/>
                        <li><strong>Dados de Pagamento</strong>: podemos coletar determinados dados relativos ao seu cartão de crédito, para efetuar o pagamento de determinados produtos e serviços adquiridos por você.</li>
                        <br/>
                        <li><strong>Dados do dispositivo</strong>: como modelo de hardware, número do IMEI e outros identificadores exclusivos do dispositivo, endereço MAC, endereço IP, versões do sistema operacional e configurações do dispositivo para acesso aos serviços. </li>
                    </ul>

                    <br/>

                    <h2>5. Com quem compartilhamos os dados coletados?</h2>
                    <p>A depender da sua utilização de nossos serviços e funcionalidades, podemos compartilhar dados com:</p>
                    <ul>
                        <li>
                            <strong>Operadoras de Saúde</strong>
                            : esse compartilhamento de dados pode ser realizado com operadoras, sempre de forma anonimizada, caso a empresa que você possui vínculo empregatício queira acesso a planos de saúde personalizados e melhor negociação de preços. Ainda neste caso, é necessária a autorização do representante legal da empresa para que haja o compartilhamento de dados anonimizados.
                            <p>base legal: consentimento, obrigação legal/regulatória.</p>
                        </li>
                        <br/>
                        <li>
                            <strong>Médicos ou profissionais de saúde</strong>
                            : poderemos compartilhar seus dados de saúde e dados de prontuário com seu médico ou outros médicos que precisem ter acesso aos seus dados e programa de desfecho clínico para realização de consultas que poderão acessá-los em um canal específico para agilizar seu atendimento, potencializando uma melhor conduta clínica pelo profissional de saúde.
                            <p>base legal: consentimento, tutela da saúde. </p>
                        </li>
                        <br/>
                        <li>
                            <strong>Representante legal da empresa</strong>
                            : poderemos compartilhar os seus dados de forma anonimizada para que a empresa em que você possui vínculo empregatício tome decisões mais assertivas de como melhorar a saúde de seus colaboradores.
                            <p>base legal: consentimento, tutela da saúde. </p>
                        </li>
                        <br/>
                        <li>
                            <strong>Terceiros</strong>
                            : Determinadas bases de dados podem ser disponibilizadas a terceiros, que sejam parceiros de negócio estratégicos para o benefício e geração de resultados mútuos, tais como o fornecimento ou melhora de nossos produtos. A base de dados não será compartilhada para propósitos de marketing por terceiros.
                            <p>base legal: legítimo interesse, consentimento e execução de contrato</p>
                            No caso de compartilhamento de Informações com terceiros, a Balto deverá se certificar de que (i) a outra pessoa jurídica possua estrutura para aplicar as regras, garantias e restrições existentes nesta Política para a transferência e posterior uso, processamento e armazenamento dos Dados Pessoais, (ii) a transferência de Dados Pessoais esteja de acordo com as legislações aplicáveis de Proteção de Dados e amparada por contrato e (iii) o processamento dos Dados Pessoais seja limitado ao propósito, para o qual foi consentida a transferência concordada pela pessoa jurídica geradora na Balto.
                        </li>
                    </ul>

                    <br/>

                    <h2>6. Por quanto tempo retemos os dados pessoais?</h2>
                    <p>Nós mantemos seus dados pessoais somente pelo tempo que for necessário para cumprir as finalidades para as quais os coletamos, observando também eventual necessidade de manutenção dos dados para atendimento a obrigações legais ou regulatórias às quais estejamos sujeitos ou mesmo para o resguardar os direitos da Balto.</p>
                    <br/>
                    <p>Para determinar o período de retenção adequado para os dados pessoais, consideramos os propósitos pelos quais os dados pessoais serão tratados, o tipo de dado processado e a existência de legislação específica que exija sua guarda e manutenção. Os dados de saúde poderão ter a retenção por até 20 anos, conforme Resolução CFM nº 1638/02 e do Parecer CFM nº 10/09 e Lei 13.787/2018.</p>

                    <br/>

                    <h2>7. Direito dos titulares</h2>
                    <p>Você possui direitos relativos à privacidade e à proteção dos seus dados pessoais. Seus direitos incluem:</p>
                    <ol>
                        <li>Confirmação de que realizamos o tratamento dos seus Dados Pessoais;</li>
                        <br/>
                        <li>Acesso aos seus Dados Pessoais;</li>
                        <br/>
                        <li>Correção de Dados Pessoais que estejam incompletos, inexatos ou desatualizados;</li>
                        <br/>
                        <li>Anonimização, bloqueio ou eliminação de Dados Pessoais desnecessários, excessivos ou tratados em desconformidade com o disposto na lei;</li>
                        <br/>
                        <li>Portabilidade dos seus Dados Pessoais a outro fornecedor de serviço ou produto, observados os nossos segredos comerciais e industriais, após a regulamentação dos meios pela autoridade de proteção de dados;</li>
                        <br/>
                        <li>Eliminação dos Dados Pessoais tratados com base no seu consentimento, exceto nas hipóteses de conservação de Dados Pessoais previstas na lei;</li>
                        <br/>
                        <li>Informação sobre com quem compartilhamos os seus Dados Pessoais;</li>
                        <br/>
                        <li>Revogação do seu consentimento para o tratamento dos seus Dados Pessoais, quando os Dados Pessoais forem tratados com base no seu consentimento;</li>
                        <br/>
                        <li>Revisão de decisões tomadas unicamente por meio de processos automatizados;</li>
                        <br/>
                        <li>Oposição a tratamento que viole a lei.</li>
                    </ol>
                    <br/>

                    <h2>8. Como protegemos os seus dados pessoais?</h2>
                    <p>Implementamos as medidas de segurança ao nosso alcance que consideramos adequadas para proteger os seus dados pessoais. Com a finalidade de garantir a proteção dos seus dados pessoais, adotamos as seguintes práticas de segurança:</p>
                    <ul>
                        <li>Acesso à base de dados restrito às empresas e aos profissionais autorizados.</li>
                        <br/>
                        <li>Criptografia na transação de dados entre os sistemas, bem como no armazenamento de determinados bancos de dados.</li>
                        <br/>
                        <li>Monitoramento aos acessos e às ações e ações realizadas em relação aos dados pessoais.</li>
                        <br/>
                        <li>Definição e distinção de funções e competências relativas às categorias de pessoas responsáveis ou envolvidas nos sistemas.</li>
                        <br/>
                        <li>Auditoria regular interna para garantir que as medidas de proteção de dados estão sendo cumpridas. </li>
                        <br/>
                        <li>Adoção de procedimentos preventivos contra incidentes de segurança da informação, bem como técnicas de remediação e gerenciamento de crises.</li>
                    </ul>
                    <p>Nós nos esforçamos para proteger suas informações, mas não podemos garantir que seus dados estejam imunes a acessos indevidos por parte de pessoas com má intenção.</p>
                    <p>Parte da segurança dos dados passa pelas atitudes dos próprios titulares, por isso, não compartilhe a senha utilizada em nossos serviços em hipótese alguma, ainda que julgue que o destinatário da senha é alguém de sua confiança.</p>

                    <br/>

                    <h2>9. Alterações nesta Política</h2>
                    <p>A Balto pode modificar, atualizar, estender ou de qualquer outra forma alterar o conteúdo e o escopo desta Política a qualquer momento e a seu exclusivo critério. Nesses casos, publicaremos essas alterações em nosso website. </p>
                    <p>As alterações nesta Política também podem ser comunicadas por e-mail, desde que o referido meio tenha sido estabelecido como um canal de comunicação entre Você e a Balto, durante a vigência de uma relação jurídica. O uso contínuo dos serviços da Balto após notificação via e-mail ou publicação no website sobre as mudanças em nossa política de privacidade, consistirá em sua aceitação dos novos termos.</p>

                    <br/>

                    <h2>10. Contato</h2>
                    <p>Se você tiver alguma dúvida ou reclamação relacionada a esta Política de Privacidade, entre em contato conosco através do e-mail <a className="dpo-contact" href="mailto:dpo@saude-360.com" title="">dpo@saude-360.com</a>. O Encarregado pode ser contatado pelo mesmo endereço.</p>
                    <p><strong>Data da última atualização:</strong> [25/05/2023]</p>
                </div>
            </Style>
        )
    }
}

export default Privacy;